<template>
  <div class="bg-cq-orange text-white text-center">
    <MasterLayout>
      <VolunteerRecap />
      <p>-- End of Report --</p>
    </MasterLayout>
  </div>
</template>

<script>
// Data is the page that Chariteers go to (if they manage any Providers) to view a recap of
// how their week, month, or year went. I could also see this being useful for Guild Leaders...
import MasterLayout from "@/components/MasterLayout.vue";
import VolunteerRecap from "../components/data/VolunteerRecap.vue";

export default {
  name: "Home",
  components: {
    MasterLayout,
    VolunteerRecap
  },
  methods: {

  },
  async created() {
  }
}
</script>