<template>
  <nav class="top-nav">
    <ul class="flex flex-row list-none items-center gap-8 m-0">
      <!-- Little Logo, does nothing -->
      <li>
        <img
          class="w-32"
          alt="Charity Quest logo"
          src="../assets/CQ-Logo.png"
        />
      </li>

      <!-- CHARITEER -->
      <!-- CHARITIES   CALENDAR   VOLUNTEERS   GROUPS   -->
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'CharityList' }"
          v-show="$store.state.playerProfile.accountType == 'Chariteer'"
        >
          Charities
        </router-link>
      </li>
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Calendar' }"
          v-show="isCalendarButtonVisible"
        >
          Calendar
        </router-link>
      </li>
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Chariteers' }"
          v-show="isVolunteersButtonVisible"
        >
          Volunteers
        </router-link>
      </li>
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Guilds' }"
          v-show="
            $store.state.playerProfile.accountType === 'Chariteer' ||
              $store.state.playerProfile.accountType === 'Provider'
          "
        >
          Guilds
        </router-link>
      </li>
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Data' }"
          v-show="isDataButtonVisible"
        >
          Data
        </router-link>
      </li>

      <!-- PROVIDER -->
      <!-- HOME   CALENDAR   QUESTS   CHARITEERS-->

      <li class="top-nav-list-item">
        <router-link
          class="top-nav-route"
          :to="{ name: 'Home' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Home
        </router-link>
      </li>
      <li class="top-nav-list-item">
        <router-link
          class="top-nav-route"
          :to="{ name: 'Calendar' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Calendar
        </router-link>
      </li>
      <li class="top-nav-list-item">
        <router-link
          class="top-nav-route"
          :to="{ name: 'Quests' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Quests
        </router-link>
      </li>
      <li class="top-nav-list-item">
        <router-link
          class="top-nav-route"
          :to="{ name: 'Chariteers' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Chariteers
        </router-link>
      </li>

      <!-- EVERYONE -->
      <!-- But right now Provider only -->
      <!-- GROUPS -->

      <li class="top-nav-list-item">
        <router-link
          class="top-nav-route"
          :to="{ name: 'Groups' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Groups
          <!-- TODO: Change this to "Group" if the user belongs to 1 Group in particular. Don't change for 0 or N group memberships -->
        </router-link>
      </li>
      <li
        class="flex-shrink-0 min-w-[12rem] ml-auto mr-6 w-max flex items-center"
      >
        <router-link
          :to="
            `/questList/${$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderId}`
          "
          class="mr-2 text-white"
        >
          <i
            class="pi pi-home text-2xl pointer-cursor"
            v-show="isVolunteersButtonVisible"
          ></i>
        </router-link>
        <Dropdown
          v-model="$store.state.currentlySelectedManagedProviderGroup"
          :options="$store.state.managedProviderGroupMemberships"
          :disabled="!isCharityListClickable"
          v-show="isCharityListButtonVisible"
          optionLabel="GroupName"
          class="text-md text-black bg-white rounded-md cursor-pointer min-w-full"
        ></Dropdown>
      </li>
      <li class="top-nav-profilePlate top-nav-list-item">
        <div v-if="$store.state.playerProfile.username != undefined">
          <div
            class="top-nav-list-item"
            @click="toggleProfileMenu"
            aria-haspopup="true"
            aria-controls="profile_menu"
          >
            {{
              $store.state.playerProfile.firstName +
                " " +
                $store.state.playerProfile.lastName
            }}
          </div>
          <Menu
            ref="profileMenu"
            id="profile_menu"
            :model="profileMenuItems"
            :popup="true"
          >
          </Menu>
        </div>
        <div v-else>
          <router-link class="top-nav-route" :to="{ name: 'Login' }">
            Login
          </router-link>

          <router-link
            class="top-nav-route"
            :to="{ name: 'CreateChariteerAccount' }"
          >
            Sign Up
          </router-link>
        </div>
      </li>
    </ul>

    <!-- MOBILE ONE IS HERE, BECAUSE I SUCK -->
    <!-- This button is used on narrow screens to access the dropdown -->
    <ul class="mobile-flexBox">
      <li class="mobile-dropdown-button-container">
        <button
          class="mobile-dropdown-button"
          v-on:click="tapMobileDropDownButton"
        >
          <img
            class="mobile-dropdown-button-image"
            alt="Hoboken Charity Quest icon"
            src="../assets/Button-Logo-Icon.png"
          />
        </button>
      </li>

      <li class="mobile-profile-plate">
        <div v-if="$store.state.playerProfile.username != undefined">
          {{
            $store.state.playerProfile.firstName +
              " " +
              $store.state.playerProfile.lastName
          }}
        </div>
        <div v-else>
          <router-link class="top-nav-route" :to="{ name: 'Login' }">
            Login
          </router-link>

          <router-link
            class="top-nav-route"
            :to="{ name: 'CreateChariteerAccount' }"
          >
            Sign Up
          </router-link>
        </div>
      </li>
    </ul>

    <ul class="top-nav-flexBox" v-show="dropDownListIsVisible">
      <!-- CHARITEER -->
      <!-- HOME   CHARITIES   -->
      <li class="dropdown-list-item">
        <router-link
          class="dropdown-nav-route"
          :to="{ name: 'CharityList' }"
          v-show="$store.state.playerProfile.accountType == 'Chariteer'"
        >
          Charities
        </router-link>
      </li>

      <!-- PROVIDER -->
      <!-- HOME   CALENDAR   QUESTS   CHARITEERS-->
      <li class="dropdown-list-item">
        <router-link
          class="dropdown-nav-route"
          :to="{ name: 'Home' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Home
        </router-link>
      </li>
      <li class="dropdown-list-item">
        <router-link
          class="dropdown-nav-route"
          :to="{ name: 'Calendar' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Calendar
        </router-link>
      </li>

      <li class="dropdown-list-item">
        <router-link
          class="dropdown-nav-route"
          :to="{ name: 'Quests' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Quests
        </router-link>
      </li>
      <li class="dropdown-list-item">
        <router-link
          class="dropdown-nav-route"
          :to="{ name: 'Chariteers' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Chariteers
        </router-link>
      </li>

      <!-- EVERYONE -->
      <!-- But right now Provider only -->
      <!-- GROUPS -->
      <li class="dropdown-list-item">
        <router-link
          class="dropdown-nav-route"
          :to="{ name: 'Groups' }"
          v-show="$store.state.playerProfile.accountType == 'Provider'"
        >
          Groups
          <!-- TODO: Change this to "Group" if the user belongs to 1 Group in particular. Don't change for 0 or N group memberships -->
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Menu from "primevue/menu";

export default {
  components: {
    Dropdown,
    Menu
  },
  props: {
    quest: Object
  },
  data() {
    return {
      dropDownListIsVisible: false,
      profileMenuItems: [
        {
          icon: "pi pi-sign-out",
          label: "Log out",
          command: () => {
            window.PlayFabClientSDK.ForgetAllCredentials();
            localStorage.setItem("email", "");
            localStorage.setItem("pwd", "");
            // Real routing to login page the page to escape from current state
            window.location.href = "/login";
          }
        }
      ]
    };
  },
  methods: {
    tapMobileDropDownButton() {
      if (this.dropDownListIsVisible) this.dropDownListIsVisible = false;
      else this.dropDownListIsVisible = true;
    },
    toggleProfileMenu(event) {
      this.$refs.profileMenu.toggle(event);
    }
  },
  computed: {
    isCalendarButtonVisible() {
      return this.$store.state.managedProviderGroupMemberships.length > 0;
    },
    isVolunteersButtonVisible() {
      if (this.$store.state.managedProviderGroupMemberships.length > 0) {
        return true;
      }

      return false;
    },
    isDataButtonVisible () {
      // let i = 0;
      // for ( i; i < this.$store.state.managedProviderGroupMemberships.length; i++ )
      // {
      //   if ( this.$store.state.managedProviderGroupMemberships[i].ManagedProviderId == "6979BBE94C41FFA5" )
      //     return true;
      // }
      if (this.$store.state.managedProviderGroupMemberships.length > 0) {
        return true;
      }

      return false;
    },
    isCharityListButtonVisible() {
      return this.$store.state.managedProviderGroupMemberships.length >= 1;
    },
    isCharityListClickable() {
      return this.$store.state.managedProviderGroupMemberships.length >= 2;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-nav {
  color: white;
  font-weight: 600;
  background-color: #2d2d2d;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-nav-flexBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.top-nav-logo {
  order: 0;
}

.top-nav-logo-image {
  height: auto;
  max-width: 100px;
}

.top-nav-list-item {
  list-style-type: none;
  /* order: 1;
  flex: 1; */
  font-size: 125%;
}

.top-nav-route {
  color: white;
  font-weight: 400;
  text-decoration: none;
}

.router-link-active {
  color: #444444;
  background-color: #ffa94e;
  font-weight: 900;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
}

.top-nav-profilePlate {
  /* flex: 10;
  order: 2; */
  text-align: right;
  text-transform: none;
  padding-right: 20px;
}

p {
  margin: 0px;
}

.mobile-flexBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.mobile-dropdown-button-container {
  background-color: rgba(255, 105, 180, 0);
}

.mobile-dropdown-button {
  background-color: rgba(172, 255, 47, 0);
  display: inline-block;
  border: none;
  cursor: pointer;
  padding: 0px;
}

.mobile-dropdown-button-image {
  max-width: 64px;
  display: block;
}

.dropdown-list-item {
  list-style-type: none;
  order: 1;
  flex: 1;
  font-size: 125%;
  text-transform: uppercase;
}

.dropdown-nav-route {
  color: white;

  font-weight: 400;
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
}

/*  The Charity Quest logo should now switch to a different image
    and also operate as a button now, to bring down the drop down.
*/

@media screen and (max-width: 800px) {
  .top-nav {
    max-width: 100%;
  }

  .top-nav-flexBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .top-nav-logo {
    display: none;
  }

  .top-nav-profilePlate {
    display: none;
  }

  .top-nav-list-item {
    display: none;
  }

  .mobile-dropdown-button-container {
    display: block;
  }

  .mobile-profile-plate {
    display: block;
  }
}

@media screen and (min-width: 801px) {
  .mobile-dropdown-button-container {
    display: none;
  }

  .mobile-profile-plate {
    display: none;
  }
}
</style>
