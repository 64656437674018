import { DateTime } from "luxon";

export const convertUTCTimeStringToLocalTimeString = utcTimeString => {
  const utcDateTime = DateTime.fromISO(utcTimeString, { zone: "utc" });
  const localDateTime = utcDateTime.toLocal();
  return localDateTime.toFormat("HH:mm");
};

export const convertUTCTimeStringToHumanLocalTimeString = utcTimeString => {
  const utcDateTime = DateTime.fromISO(utcTimeString, { zone: "utc" });
  const localDateTime = utcDateTime.toLocal();
  return localDateTime.toFormat("hh:mm a");
};
