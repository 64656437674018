<template>
  <button
    class="guildCard flex items-center lg:justify-start justify-center w-[300px] lg:w-[500px] lg:max-w-[47.5%]"
    v-on:click="onClick"
  >
    <!-- We used to check if charity.isVisible but that's dumb so I removed it. Chesterton's fence? -->
    <img
      src="../assets/quest-guild-flag.png"
      alt="Guild Flag"
      class="w-[100px] h-[100px] lg:block hidden lg:not object-cover"
    />
    <div class="guild-text lg:text-left text-center max-h-full">
      <h4 class="lg:text-sm text-xs m-0 p-0">{{ guild.name }}</h4>
      <h5 class="lg:text-sm text-xs text-gray-500 m-0 p-0">{{ guild.classification }}</h5>
      <p class="lg:text-sm text-xs">{{ guild.description }}</p>
    </div>
  </button>
</template>

<script>
export default {
  name: "GuildCard",
  components: {},
  props: {
    guild: Object
  },
  methods: {
    onClick() {
      // Commenting out the Amazon tracking to make sure that it can receive the guild object
      // var tagsObj = {
      // 	messageType: "Chariteer Clicks Charity To View All Quests",
      // 	charityName: this.charity.name,
      // 	charityID: this.charity.providerID,
      // };
      // this.$store.dispatch("clickTrackLogForAmazon", tagsObj);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.guilds-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.guildCard {
  height: 175px;
  cursor: pointer;
  font-family: Palanquin, Montserrat, Helvetica, Arial, sans-serif;
  font-size: 110%;
  background-color: white;
  border: none;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.15s ease-in-out;
}
.guildCard:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.5);
}

.guild-text h4,
.guild-text p {
  width: 100%;
}
</style>
