import { createStore } from "vuex";

export default createStore({
  state: {
    localVersionIsOutdated: false,
    playerProfile: {},
    inventoryContracts: [],
    inventoryMisc: [],
    groupMemberships: [],
    managedProviderGroupMemberships: [],
    count: 0,
    providers: [
      {
        id: 0,
        providerID: "E3B32B3905EF829",
        providerManagementGroupIDs: ["AD572723AE001470"],
        name: "Community Lifestyle",
        district: 1,
        isVisible: true
      },
      {
        id: 1,
        providerID: "6427430127350B1C",
        name: "Street Life Ministry",
        providerManagementGroupIDs: ["3B18760764583578"],
        district: 1,
        isVisible: true
      },
      {
        id: 2,
        providerID: "847F21FAD0FFC647",
        name: "The Hoboken Community Center Pantry",
        district: 1,
        providerManagementGroupIDs: ["7905FCF23D665DD3"],
        isVisible: true
      },
      {
        id: 3,
        providerID: "6979BBE94C41FFA5",
        name: "The Hoboken Shelter",
        district: 1,
        providerManagementGroupIDs: ["D62A90374140FD06"],
        isVisible: true
      },
      {
        id: 4,
        providerID: "4DD7296EFD66BEB9",
        name: "Computers 4 People",
        district: 1,
        providerManagementGroupIDs: ["9B91ABAF5174CEDC"],
        isVisible: true
      },
      {
        id: 5,
        providerID: "362AF69D1DACA18",
        name: "Party With Purpose",
        district: 1,
        providerManagementGroupIDs: ["63DBE8B64CFEB9A6"],
        isVisible: true
      },
      {
        id: 6,
        providerID: "F9AFF32554D0C2AB",
        name: "City Challenge Race Youth Foundation",
        district: 1,
        providerManagementGroupIDs: ["68B38BD691C8F430"],
        isVisible: true
      },
      {
        id: 7,
        providerID: "9B8391190BC762C6",
        name: "The Hoboken Family Alliance",
        district: 1,
        providerManagementGroupIDs: ["71CEBEFCAE7C3F7B"],
        isVisible: true
      },
      {
        id: 8,
        providerID: "C781DBBD407192AF",
        name: "Hoboken Unified Giving",
        district: 1,
        providerManagementGroupIDs: ["659ABCF6C0AC93C9"],
        isVisible: true
      },
      {
        id: 9,
        providerID: "F8F9FC40BB1AF87A",
        name: "Triangle Park Community Center",
        district: 6,
        providerManagementGroupIDs: ["B8C7D024994A9073"],
        isVisible: false
      },
      {
        id: 10,
        providerID: "29A2D4143AD32231",
        name: "Temple Beth-El Pantry",
        district: 3,
        providerManagementGroupIDs: ["75128ABCBE270F34"],
        isVisible: true
      },
      {
        id: 11,
        providerID: "32666A142E725B16",
        name: "Dvine Konektion",
        district: 10,
        providerManagementGroupIDs: ["4E213446BD783625"],
        isVisible: true
      },
      {
        id: 12,
        providerID: "1BBBF60741F9E7F3",
        name: "Amazing Truth Society",
        district: 1,
        providerManagementGroupIDs: ["FE8F13FC1D73D3D"],
        isVisible: true
      },
      {
        id: 13,
        providerID: "FE3C7AD590819BBD",
        name: "Friends of Riverview Fisk Park",
        district: 2,
        providerManagementGroupIDs: ["5056CC8BB6CAE6F1"],
        isVisible: true
      },
      {
        id: 14,
        providerID: "5378D57B798F2822",
        name: "Let's Celebrate",
        district: 3,
        providerManagementGroupIDs: ["414A88F0F763602A"],
        isVisible: true
      },
      {
        id: 15,
        providerID: "A1379354BA19EA12",
        name: "Team Wilderness",
        district: 4,
        providerManagementGroupIDs: ["E64A2DB044600F8F"],
        isVisible: true
      },
      {
        id: 16,
        providerID: "DA683CCDD07621BE",
        name: "St. Philip Neri Mommy Storeroom",
        district: 16,
        providerManagementGroupIDs: ["616385975B96C92C"],
        isVisible: true
      },
      {
        id: 17,
        providerID: "EBA058E13ED2DCC3",
        name: "Oasis - A Haven for Women and Children",
        district: 33,
        providerManagementGroupIDs: ["60182C45D8A6B7E5"],
        isVisible: false
      },
      {
        id: 18,
        providerID: "6D7218A69DB42F69",
        name: "Off The Streets Staten Island",
        district: 31,
        providerManagementGroupIDs: ["AE1D26AED426C042"],
        isVisible: false
      },
      {
        id: 19,
        providerID: "819524BB4F1F1472",
        name: "St. Edward Food Pantry at Mt. Loretto",
        district: 12,
        providerManagementGroupIDs: ["834B50AAD45CC95E"],
        isVisible: true
      },
      {
        id: 20,
        providerID: "E3A2A34925B7A518",
        name: "Love Thy Neighbor",
        district: 3,
        providerManagementGroupIDs: ["CF5A954E5A672A5D"],
        isVisible: true
      },
      {
        id: 21,
        providerID: "14D28C7C8E55F14A",
        name: "The Open Door NJNY",
        district: 10,
        providerManagementGroupIDs: ["540354EF347C421A"],
        isVisible: true
      },
      {
        id: 22,
        providerID: "D9F89B018C7E5B54",
        name: "Welcome Home",
        district: 3,
        providerManagementGroupIDs: ["DE29AB64F9B3D30D"],
        isVisible: true
      },
      {
        id: 23,
        providerID: "11B525A520DEB108",
        name: "The Mercy House",
        district: 6,
        providerManagementGroupIDs: ["6B10D10136D15E29"],
        isVisible: true
      },
      {
        id: 24,
        providerID: "AC0D97CB181D32B8",
        name: "Good Samaritan Charity Group",
        district: 17,
        providerManagementGroupIDs: ["C81B2EDA548352DB"],
        isVisible: true
      },
      {
        id: 25,
        providerID: "7F6860372DB1C8E0",
        name: "St. Mary Advocates",
        district: 1,
        providerManagementGroupIDs: ["510B1E3D1F7B3190"],
        isVisible: true
      },
      {
        id: 36,
        providerID: "A66BE0F1D34153EC",
        name: "Annie Clyde Holt Food Pantry",
        district: 36,
        providerManagementGroupIDs: ["42A7AB32E33C37A9"],
        isVisible: true
      },
      {
        id: 27,
        providerID: "8394C376DE71D4DD",
        name: "Round 2 Resources",
        district: 1,
        providerManagementGroupIDs: ["AB1F29B4A6672EDE"],
        isVisible: true
      },
      {
        id: 28,
        providerID: "9203F85379D0A9A4",
        name: "Weehawken Volunteer First Aid Squad",
        district: 9,
        providerManagementGroupIDs: ["0"],
        isVisible: true
      },
      {
        id: 29,
        providerID: "B092AC88B3BBFC01",
        name: "Computers 4 People MA",
        district: 14,
        providerManagementGroupIDs: ["FCC7C16C724E2367"],
        isVisible: true
      },
      {
        id: 30,
        providerID: "53E56B7FF5ABE676",
        name: "Shirley L. Armstrong Scholarship, Soup Kitchen, and Food Pantry",
        district: 19,
        providerManagementGroupIDs: ["AF8FD5E0F1D3D4"],
        isVisible: true
      },
      {
        id: 31,
        providerID: "A5CF8B0D5BDF1771",
        name: "Grace Community Services",
        district: 4,
        providerManagementGroupIDs: ["0"],
        isVisible: true
      },
      {
        id: 32,
        providerID: "779B67D089D0E093",
        name: "For My Good Work",
        district: 18,
        providerManagementGroupIDs: ["AB9977E0815BA718"],
        isVisible: true
      },
      {
        id: 33,
        providerID: "9395444193FA2B23",
        name: "Joanne Hollis Gardens Resident Association PCDC",
        district: 21,
        providerManagementGroupIDs: ["ED2FACE6245BED6D"],
        isVisible: true
      },
      {
        id: 34,
        providerID: "814AE59680A238D2",
        name: "Better Tomorrows Jersey City",
        district: 3,
        providerManagementGroupIDs: ["E57CD730EEB8F97C"],
        isVisible: false
      },
      {
        id: 35,
        providerID: "1312EC849B2C70D2",
        name: "Radiant Church Pop-Up Food Pantry",
        district: 22,
        providerManagementGroupIDs: ["6B58CA1145DFBAB6"],
        isVisible: true
      },
      {
        id: 36,
        providerID: "F14001AE1164A6F8",
        name: "New Life Pantry",
        district: 23,
        providerManagementGroupIDs: ["86677036F505D9B7"],
        isVisible: true
      },
      {
        id: 37,
        providerID: "AA315C88EF59E453",
        name: "Transition Professionals",
        district: 24,
        providerManagementGroupIDs: ["10379E9F379386C6"],
        isVisible: true
      },
      {
        id: 38,
        providerID: "911E74C3DAF9F8B6",
        name: "Mom's Food Pantry",
        district: 25,
        providerManagementGroupIDs: ["BB692D3024390C78"],
        isVisible: true
      },
      {
        id: 39,
        providerID: "D04ADD09457BE496",
        name: "Garfield YMCA",
        district: 30,
        providerManagementGroupIDs: ["47A1B28ADF7AD3E7"],
        isVisible: true
      },
      {
        id: 40,
        providerID: "A484E05A5358B7CF",
        name: "Zion Cares",
        district: 5,
        providerManagementGroupIDs: ["EF9BCAB99858DDED"],
        isVisible: true
      },
      {
        id: 41,
        providerID: "E8021A507970C563",
        name: "The WholeSum Pantry",
        district: 32,
        providerManagementGroupIDs: ["8BD30760EECB7195"],
        isVisible: true
      },
      {
        id: 42,
        providerID: "A5794CAAB5391AD1",
        name: "Jewish Family and Children's Services of Northern New Jersey",
        district: 3,
        providerManagementGroupIDs: ["BA96D4DD32B5B8CC"],
        isVisible: true
      },
      {
        id: 43,
        providerID: "20527FEBCC2CDFC5",
        name: "St. Francis Church",
        district: 1,
        providerManagementGroupIDs: ["FEA03420FE18448D"],
        isVisible: true
      },
      {
        id: 44,
        providerID: "60335A4B0EA82CAC",
        name: "Neighborhood Health",
        district: 21,
        providerManagementGroupIDs: ["30B8EA8F442C3BE1"],
        isVisible: true
      },
      {
        id: 45,
        providerID: "2600978B03F9189B",
        name: "Deliverance Children's Ministry",
        district: 6,
        providerManagementGroupIDs: ["58C8A13FF9448745"],
        isVisible: false
      },
      {
        id: 46,
        providerID: "C79A352EE800BA2D",
        name: "United Passaic Organization",
        district: 34,
        providerManagementGroupIDs: ["E1B6BC546C2AA416"],
        isVisible: false
      },
      {
        id: 47,
        providerID: "3D4451DDD84288B7",
        name: "Jefferson Park Ministries",
        district: 19,
        providerManagementGroupIDs: ["AB1FA9A200BAA266"],
        isVisible: false
      },
      {
        id: 48,
        providerID: "E9683F01D146AB2E",
        name: "United Synagogue of Hoboken",
        district: 1,
        providerManagementGroupIDs: ["652C9186F4CEC5E1"],
        isVisible: false
      },
      {
        id: 49,
        providerID: "1320319AAA026BA1",
        name: "New Jersey Reentry Corporation",
        district: 28,
        providerManagementGroupIDs: ["856D72EE1F252284"],
        isVisible: false
      },
      {
        id: 50,
        providerID: "F0707F72F3C2C1F8",
        name: "Family Promise of Hudson County",
        district: 4,
        providerManagementGroupIDs: ["E833ACD0FFCB1050"],
        isVisible: false
      },
      {
        id: 51,
        providerID: "C80EA27C8CFEC177",
        name: "JC Families",
        district: 4,
        providerManagementGroupIDs: ["356BA5D2F37974BF"],
        isVisible: false
      },
      {
        id: 52,
        providerID: "4A57780108B4C7FA",
        name: "Bergen County SPCA",
        district: 24,
        providerManagementGroupIDs: ["55CA563EAF9B8457"],
        isVisible: false
      },   
      {
        id: 53,
        providerID: "F173E9F23ED74763",
        name: "YWCA of Union County",
        district: 19,
        providerManagementGroupIDs: ["BEF1BE0A9498D93F"],
        isVisible: false
      },
      {
        id: 54,
        providerID: "E90D4007443E821A",
        name: "CEA Food Pantry",
        district: 19,
        providerManagementGroupIDs: ["D48358718DD7CA3A"],
        isVisible: false
      }
      
      
      // {
      //   id: 53,
      //   providerID: "FD0320648E13DFB1",
      //   name: "Test Provider",
      //   district: 1,
      //   providerManagementGroupIDs: ["E74EAD472C0EA0AF"],
      //   isVisible: false
      // }

      // {
      //   id: 47,
      //   providerID: "14AAB5B3B3FEBF52",
      //   name: "Muslim Community Soup Kitchen",
      //   district: 21,
      //   providerManagementGroupIDs: ["9ED8280C05CD49C7"],
      //   isVisible: false
      // }

      // {
      //   id: 33,
      //   providerID: "1C5D7C92429039C1",
      //   name: "Hoboken Catholic Academy",
      //   district: 1,
      //   providerManagementGroupIDs: ["BBB90B440EF54359"],
      //   isVisible: true
      // },

      // {
      //   id: 17,
      //   providerID: "C0AB2EFF8CF5A32",
      //   name: "Cross Road Foundation",
      //   district: 11,
      //   providerManagementGroupIDs: ["BC827D97954BF5A7"],
      //   isVisible: true
      // },
      // {
      //   id: 18,
      //   providerID: "3B9DE08B449B39D0",
      //   name: "Staten Island Catholic Worker",
      //   district: 11,
      //   providerManagementGroupIDs: ["9354BC1183C558F5"],
      //   isVisible: true
      // },

    ],
    currentlyViewedProvider: {},
    events: [],
    currentlyViewedQuest: {},
    questApplicationIsLoading: false,
    questApplicationSubmittedSuccessfully: false,
    questApplicationErrorCode: -1,
    questDetailsChangesAreLoading: false,
    questDetailsChangesSubmittedSuccessfully: false,
    currentlyViewedGroup: {},
    currentlyViewedGroupMembers: [],
    currentlyViewedGroupApplications: [],
    currentlyViewedChariteer: {},
    chariteerDictionary: {}, // Used by Chariteers and Providers - once we download a Chariteer's info, just save it instead of redownloading every time!
    chariteerDictionaryLength: 0,
    totalProviderMinutesDictionary: {},
    providerQuests: null,
    providerCompletedQuests: null,
    providerDraftQuests: {
      requestedItems: [],
      specialEvents: [],
      fundraisers: []
    },
    providerQuestTemplates: {
      nextQuestID: 1337,
      rules: [],
      stamps: [],
      bundles: []
    },
    providerLocations: null,
    providerServiceRecords: [],
    providerSurveys: [],
    providerGroupMembers: [],
    currentlySelectedStamp: null,
    currentlySelectedStampCollection: {
      stamps: [
        {
          index: 0,
          selected: false,
          stampTitle: "Stamp Test",
          icon: "war",
          eventType: "OSV",
          eventTitle: "Stamp Test",
          description:
            "We're expecting a shipment of food. Help us unload the truck, and open the packages. We'll organize the pantry while we await the delivery.\n\nThis Quest involves a lot of lifting heavy items and walking!",
          serviceTags: ["body"],
          flexibleMinimumTime: null,
          locationId: 4,
          resourceMinNumber: 4,
          resourceDesiredNumber: 5,
          resourceMaxNumber: 6,
          agePolicyIndex: 2,
          equipment:
            "Comfortable clothing that can get dirty, closed-toed shoes, water bottle."
        },
        {
          index: 1,
          selected: false,
          stampTitle: "Stamp Test 2",
          icon: "shadow",
          eventType: "OSV",
          eventTitle: "Stamp Test",
          description:
            "We're expecting a shipment of food. Help us unload the truck, and open the packages. We'll organize the pantry while we await the delivery.\n\nThis Quest involves a lot of lifting heavy items and walking!",
          serviceTags: ["body"],
          flexibleMinimumTime: null,
          locationId: 4,
          resourceMinNumber: 4,
          resourceDesiredNumber: 5,
          resourceMaxNumber: 6,
          agePolicyIndex: 2,
          equipment:
            "Comfortable clothing that can get dirty, closed-toed shoes, water bottle."
        }
      ],
      bundles: []
    },
    currentlySelectedApprovedLocations: {
      questLocations: [
        {
          index: 0,
          selected: false,
          name: "The Hoboken Cove",
          address: "Mah",
          detail: "U r cool man :)"
        },
        {
          index: 1,
          selected: false,
          name: "The Van By The River",
          address: "We'll do it live!",
          detail: "We'll do it live."
        },
        {
          index: 2,
          selected: false,
          name: "Stephen R. Gregg Park",
          address: "930 John F. Kennedy Blvd, Bayonne, NJ US 07002",
          detail: "Park in Bayonne or whatever."
        }
      ]
    },
    currentlySelectedStampConfigFile: {
      stampBook: [
        {
          name: "Programs",
          stamps: [
            {
              id: 4117,
              stampTitle: "CFBNJ Delivery",
              icon: "stamp_bread_01"
            },
            {
              id: 4119,
              stampTitle: null,
              icon: null
            }
          ]
        },
        {
          name: "Fundraising",
          stamps: []
        },
        {
          name: "Seasonal",
          stamps: [
            {
              id: 4120,
              stampTitle: "Xmas Party",
              icon: "stamp_envelope_01"
            }
          ]
        }
      ],
      bundleBook: []
    },
    testDictionary: {
      GROGU: { playFabID: "GROGU", name: "Frank", last: "DiCola" },
      "1234": { playFabID: "1234", name: "Paul", last: "DiCola" },
      "90A12": { playFabID: "90A12", name: "Drago", last: "Winnebago" }
    },
    currentlySelectedManagedProviderGroup: {}
  },
  mutations: {
    INCREMENT_COUNT(state) {
      state.count += 1;
    },
    SET_MAINTENANCE_STATUS(state, weem) {
      // console.log("Maintenance Status set for " + state)
      // console.log("Weem? " + weem)
    },
    SET_PLAYER_PROFILE(state, profile) {
      // console.log("SET_PLAYER_PROFILE succeeded for " + profile.username)
      state.playerProfile.masterPlayerID = profile.masterPlayerID;
      state.playerProfile.accountType = profile.accountType;
      state.playerProfile.firstName = profile.firstName;
      state.playerProfile.lastName = profile.lastName;

      if (profile.dob && profile.age) {
        console.log("User is " + profile.age + " years old.");
        state.playerProfile.age = profile.age;
        state.playerProfile.dateOfBirth = profile.dob;
      } else {
        console.log("Could not determine user age.");
      }

      if (profile.phoneNumber)
        state.playerProfile.phoneNumber = profile.phoneNumber;
    },
    SET_PLAYER_USERNAME(state, username) {
      // console.log("SET_PLAYER_USERNAME succeeded for " + username)
      state.playerProfile.username = username;
    },
    SET_PLAYER_EMAIL(state, email) {
      // console.log("SET_PLAYER_EMAIL succeeded for " + email)
      state.playerProfile.emailAddress = email;
    },
    SET_PLAYER_ENTITY_INFORMATION(state, entityToken) {
      // console.log("SET_PLAYER_ENTITY_INFORMATION succeeded for " + entityToken.Entity.Id)
      state.playerProfile.entityID = entityToken.Entity.Id;
      state.playerProfile.entityType = entityToken.Entity.Type;
      state.playerProfile.userAuthorizationToken = entityToken.EntityToken;

      // TO DO: You should just have one function for filling the Player Profile
      // WILL:  SET_PLAYER_PLAYER(state, json) {
      //          state.playerprofile = { ...json }
      //        }

      // For now... localStorage:
      localStorage.setItem("accessToken", entityToken.EntityToken);
      // console.log("LocalStorage Set! " + localStorage.getItem("accessToken"));
    },
    SET_PROVIDER(state, charity) {
      // console.log("SET_PROVIDER succeeded for " + charity.charityName + " " + charity.charityID)
      state.currentlyViewedProvider = charity;
    },
    SET_EVENTS(state, events) {
      // console.log("SET_EVENTS succeeded.")
      state.events = events;
    },
    SET_EVENT(state, event) {
      state.currentlyViewedQuest = event;
      // console.log("Our new currently viewed Quest is:")
      // console.log(state.currentlyViewedQuest)
    },
    SET_CHARITEER_NAME(state, chariteerInfo) {
      for (
        var i = 0;
        i < state.currentlyViewedQuest.volunteerInfo.length;
        i++
      ) {
        if (
          state.currentlyViewedQuest.volunteerInfo[i].userID == chariteerInfo.id
        ) {
          state.currentlyViewedQuest.volunteerInfo[i].name =
            chariteerInfo.username;
        }
      }

      // console.log("SET_CHARITEER_NAME succeeded for " + chariteerInfo.username + " which has ID of " + chariteerInfo.id)
    },
    SET_CHARITEER_PLAYER_PUBLISHER_DATA(state, chariteerInfo) {
      for (
        var i = 0;
        i < state.currentlyViewedQuest.volunteerInfo.length;
        i++
      ) {
        if (
          state.currentlyViewedQuest.volunteerInfo[i].userID == chariteerInfo.id
        ) {
          state.currentlyViewedQuest.volunteerInfo[i].firstName =
            chariteerInfo.realFirstName;
          state.currentlyViewedQuest.volunteerInfo[i].lastName =
            chariteerInfo.realLastName;
          state.currentlyViewedQuest.volunteerInfo[i].phoneNumber =
            chariteerInfo.realPhoneNumber;
          state.currentlyViewedQuest.volunteerInfo[i].age =
            chariteerInfo.realAge;
        }
      }

      // console.log("SET_CHARITEER_PLAYER_PUBLISHER_DATA succeeded for " + chariteerInfo.realFirstName + " "  + chariteerInfo.realLastName + " who has ID of " + chariteerInfo.id)
    },
    SET_CHARITEER_PLAYER_PROFILE_DATA(state, chariteerInfo) {
      for (
        var i = 0;
        i < state.currentlyViewedQuest.volunteerInfo.length;
        i++
      ) {
        if (
          state.currentlyViewedQuest.volunteerInfo[i].userID == chariteerInfo.id
        ) {
          state.currentlyViewedQuest.volunteerInfo[i].emailAddress =
            chariteerInfo.realEmailAddress;
        }
      }

      // console.log("SET_CHARITEER_PLAYER_PROFILE_DATA succeeded.")
    },
    SET_CHARITEER_COMPLETED_QUESTS(state, credits) {
      console.log("SET_CHARITEER_COMPLETED_QUESTS succeeded.");
    },
    SET_CHARITEER_PROGRESS_WITH_INDIVIDUAL_PROVIDERS(state, dictionary) {
      state.totalProviderMinutesDictionary = dictionary;
      // console.log(state.totalProviderMinutesDictionary);
      // console.log("SET_CHARITEER_PROGRESS_WITH_INDIVIDUAL_PROVIDERS succeeded.");
    },
    SET_INVENTORY(state, inventoryInfo) {
      // console.log("SET_INVENTORY succeeded.")

      // Wipe the inventory and start anew
      state.inventoryContracts = [];

      for (var i = 0; i < inventoryInfo.items.length; i++) {
        if (inventoryInfo.items[i].ItemClass == "Contract") {
          state.inventoryContracts.push(inventoryInfo.items[i]);
        } else {
          state.inventoryMisc.push(inventoryInfo.items[i]);
        }
      }
      // console.log("Charity Medallion balance is " + inventoryInfo.charityMedallionBalance + " Medallions.")
      // console.log(state.inventoryContracts)
    },
    SET_GROUP_MEMBERSHIPS(state, groupInfo) {
      if (groupInfo.length > 0) {
        // console.log("SET_GROUP_MEMBERSHIPS succeeded for " + groupInfo.length + " Group(s).");
        // console.log("Checking " + groupInfo.length + " Group(s) to see which ones are Providers.");
        // state.managedProviderGroupMemberships = []; <== this breaks our RBAC!

        // This ought to be replaced, now that we can get RBAC and Managed Guild ids straight
        // from the database without going through a loop of 50 Providers each time
        var i = 0; // Iterate through this user's Group Memberships
        var j = 0; // Iterate through all of our hardcoded Providers, everytime
        var k = 0; // Check to make sure we're not adding this twice...

        for (i = 0; i < groupInfo.length; i++) {
          // console.log("Is " + groupInfo[i].GroupName + " a Provider?");
          for (j = 0; j < state.providers.length; j++) {
            if (state.providers[j].providerManagementGroupIDs != undefined) {
              // console.log("Provider has this management group: " + state.providers[j].providerManagementGroupIDs[0]);

              if ( state.providers[j].providerManagementGroupIDs[0] == groupInfo[i].Group.Id)
              {
                groupInfo[i].ManagedProviderName = state.providers[j].name;
                groupInfo[i].ManagedProviderId = state.providers[j].providerID;
                // console.log("Match! User manages this Provider:");
                // console.log(groupInfo[i]);

                var weNeedToAddThisGroup = true;

                // Before we add this group, let's check... do we already have it?
                for (k = 0; k < state.managedProviderGroupMemberships.length; k++)
                {
                  if ( state.managedProviderGroupMemberships[k].ManagedProviderId == groupInfo[i].ManagedProviderId )
                  {
                    console.log("Duplicate found! Ending loop early.");
                    k = state.managedProviderGroupMemberships.length + 1;
                    weNeedToAddThisGroup = false;
                  }
                }

                if ( weNeedToAddThisGroup )
                  state.managedProviderGroupMemberships.push(groupInfo[i]);
                else
                  console.log(groupInfo[i].ManagedProviderName + " would be a duplicate Management Group. Skipped!");
              }
            } else {
              // console.log("Skipping, Provider does not have any Management Groups.");
            }
          }
        }
      } else {
        // console.log("SET_GROUP_MEMBERSHIPS succeeded for an empty list of Group Memberships.")
      }

      // console.log(state.groupMemberships);

      state.groupMemberships = groupInfo;
    },
    SET_GROUP_MEMBERS(state, membersList) {
      // console.log("SET_GROUP_MEMBERS succeeded.")

      state.currentlyViewedGroupMembers = membersList;
    },
    SET_GROUP_MEMBER_PLAYER_PUBLISHER_DATA(state, publisherData) {
      // My plan to directly place everything failed because "i" is an unreliable tracer for the index. So we have to do this crap.
      // For the record I hate this and I want a better system. I do not think this system will withstand the weight of an entire highschool class of boys.
      // I need to look up dictionaries and use them.

      var roleIndex = 0;
      var memberIndex = 0;
      var stillLooking = true;

      // console.log("Beginning loop")

      for (
        roleIndex;
        roleIndex < state.currentlyViewedGroupMembers.length;
        roleIndex++
      ) {
        // console.log("Looping in the " + roleIndex + " Role now...")

        for (
          memberIndex = 0;
          memberIndex <
          state.currentlyViewedGroupMembers[roleIndex].Members.length;
          memberIndex++
        ) {
          if (stillLooking) {
            // console.log("Looping in the " + memberIndex + " Member now...")
            if (
              state.currentlyViewedGroupMembers[roleIndex].Members[memberIndex]
                .Lineage.master_player_account.Id == publisherData.ID
            ) {
              // console.log("Placing the name " + publisherData.firstName + " into the Group Members array.")
              state.currentlyViewedGroupMembers[roleIndex].Members[
                memberIndex
              ].firstName = publisherData.firstName;
              state.currentlyViewedGroupMembers[roleIndex].Members[
                memberIndex
              ].lastName = publisherData.lastName;
              // console.log("Done.")
              stillLooking = false;
            }
          }
        }
      }
    },
    SET_GROUP_APPLICATIONS(state, applicationsList) {
      // console.log("SET_GROUP_APPLICATIONS succeeded.")

      state.currentlyViewedGroupApplications = applicationsList;
    },
    SET_GROUP_APPLICANT_PLAYER_PUBLISHER_DATA(state, publisherData) {
      var applicantIndex = 0;

      // console.log("Beginning loop")

      for (
        applicantIndex;
        applicantIndex < state.currentlyViewedGroupApplications.length;
        applicantIndex++
      ) {
        // console.log("Looping in the " + applicantIndex + " Applicant now...")

        if (
          state.currentlyViewedGroupApplications[applicantIndex].Entity.Lineage
            .master_player_account.Id == publisherData.ID
        ) {
          // console.log("Placing the name " + publisherData.firstName + " into the Group Applicants array.")
          state.currentlyViewedGroupApplications[applicantIndex].firstName =
            publisherData.firstName;
          state.currentlyViewedGroupApplications[applicantIndex].lastName =
            publisherData.lastName;
          state.currentlyViewedGroupApplications[
            applicantIndex
          ].isPending = true; // This is for UI display
          // console.log("Done.")

          applicantIndex = state.currentlyViewedGroupApplications.length;
        }
      }
    },
    ACCEPT_APPLICATION_TO_GROUP(state, applicantEntityKey) {
      // console.log("ACCEPT_APPLICATION_TO_GROUP succeeded.")

      // now this sucks but we need to go through applications, find this POS, and change isPending to false
      // console.log(state.currentlyViewedGroupApplications[0] + " garg " + applicantEntityKey.Id)

      var applicantIndex = 0;

      // console.log("Beginning loop")

      for (
        applicantIndex;
        applicantIndex < state.currentlyViewedGroupApplications.length;
        applicantIndex++
      ) {
        // console.log("Looping in the " + applicantIndex + " Applicant now...")

        if (
          state.currentlyViewedGroupApplications[applicantIndex].Entity.Key
            .Id == applicantEntityKey.Id
        ) {
          // console.log("Changing Applicant so it isn't Pending anymore.")
          state.currentlyViewedGroupApplications[
            applicantIndex
          ].isPending = false;
          // console.log("Done.")

          applicantIndex = state.currentlyViewedGroupApplications.length;
        }
      }
    },
    CHANGE_GROUP_ROLE_FOR_SINGLE_USER(state, bundle) {
      // console.log("CHANGE_GROUP_ROLE_FOR_SINGLE_USER succeeded.")
      // console.log(state.playerProfile)

      // Now we need to move that user from one array to another
      // console.log("Immediately access this user within list " + bundle.originRoleIndex + " at index " + bundle.memberIndex)
      var memberData =
        state.currentlyViewedGroupMembers[bundle.originRoleIndex].Members[
          bundle.memberIndex
        ];

      // Find the array this belongs in, and push it in. Find the Destination array.
      var i = 0;
      for (i = 0; i < state.currentlyViewedGroupMembers.length; i++) {
        if (
          state.currentlyViewedGroupMembers[i].RoleId ==
          bundle.destinationRoleID
        ) {
          // console.log("Pushing onto the end of " + state.currentlyViewedGroupMembers[i].RoleId + " (" + bundle.destinationRoleID + ")")
          state.currentlyViewedGroupMembers[i].Members.push(memberData);
        }
      }

      // If we can find it instantly, we can remove it instantly
      state.currentlyViewedGroupMembers[bundle.originRoleIndex].Members.splice(
        bundle.memberIndex,
        1
      );
    },

    SIGN_UP_FOR_QUEST(state) {
      // console.log("SIGN_UP_FOR_QUEST succeeded for " + state.currentlyViewedQuest.eventTitle + " (" + state.currentlyViewedQuest.eventID + ")")
    },
    BAIL_FROM_QUEST(state) {
      // console.log("Bailing out of " + state.currentlyViewedQuest.eventTitle)
    },
    SUBMIT_APPLICATION(state) {
      // console.log("SUBMIT_APPLICATION succeeded for " + state.currentlyViewedQuest.eventTitle)

      // Set the loading flag to false
      state.questApplicationIsLoading = false;

      // Set the success popup flag to true
      state.questApplicationSubmittedSuccessfully = true;
    },

    // PROVIDERS
    SET_ALL_PROVIDER_QUESTS(state, quests) {
      // console.log("All Quests have been added to state.")
      // console.log(quests)
      state.providerQuests = quests;
    },
    SET_ALL_PROVIDER_COMPLETED_QUESTS(state, completedQuests) {
      // console.log("All Completed Quests have been added to state.")
      // console.log(completedQuests)
      state.providerCompletedQuests = completedQuests;
    },
    SET_ALL_PROVIDER_QUEST_TEMPLATES(state, questTemplates) {
      // console.log("All Templates have been added to state.")
      // console.log(questTemplates)
      state.providerQuestTemplates = questTemplates;

      var i;

      // SET DATA WE WILL USE FOR BUNDLES
      for (i = 0; i < state.providerQuestTemplates.bundles.length; i++) {
        state.providerQuestTemplates.bundles[i].index = i;
        state.providerQuestTemplates.bundles[i].selected = false;
        // console.log("Set the index of " + state.providerQuestTemplates.bundles[i].bundleID + " to " + state.providerQuestTemplates.bundles[i].index)
        // console.log("Set the selected of " + state.providerQuestTemplates.bundles[i].bundleID + " to " + state.providerQuestTemplates.bundles[i].selected)
      }

      // SET DATA WE WILL USE FOR STAMPS
      for (i = 0; i < state.providerQuestTemplates.stamps.length; i++) {
        state.providerQuestTemplates.stamps[i].index = i;
        state.providerQuestTemplates.stamps[i].selected = false;
        // console.log("Set the index of " + state.providerQuestTemplates.stamps[i].stampID + " to " + state.providerQuestTemplates.stamps[i].index)
        // console.log("Set the selected of " + state.providerQuestTemplates.stamps[i].stampID + " to " + state.providerQuestTemplates.stamps[i].selected)
      }

      // DONT FORGET RULES. IDK HOW THAT WILL WORK ACTUALLY
    },
    SET_ALL_PROVIDER_QUEST_LOCATIONS(state, locationObject) {
      // console.log("All Locations have been added to state.")
      state.providerLocations = locationObject.locations;
    },
    SET_PROVIDER_SERVICE_RECORDS(state, serviceRecords) {
      // console.log(serviceRecords)
      state.providerServiceRecords = serviceRecords;
    },
    SET_PROVIDER_SURVEYS(state, surveys) {
      // console.log(surveys)
      state.providerSurveys = surveys;
    },
    SET_PROVIDER_GROUP_MEMBERS(state, members) {
      state.providerGroupMembers = members;
      // console.log("SET_PROVIDER_GROUP_MEMBERS succeeded!")
      // console.log(state.providerGroupMembers)
    },
    ADD_CHARITEER_INFO_TO_DICTIONARY(state, chariteerInfo) {
      // console.log(chariteerInfo.realLastName + ", " + chariteerInfo.realFirstName + " has been added to the Chariteer Dictionary: ")
      // console.log(chariteerInfo)

      if (state.chariteerDictionary[chariteerInfo.playFabID] == undefined) {
        // Define it!
        state.chariteerDictionary[chariteerInfo.playFabID] = chariteerInfo;
        state.chariteerDictionaryLength++;
      } else {
        // Maybe we already got an email here...
        // Only set the First Name, Last Name, Phone Number, and Group Role Name
        // console.log(chariteerInfo.realFirstName + " " + chariteerInfo.realLastName + " is aleady in the dictionary, adding info...")
        state.chariteerDictionary[chariteerInfo.playFabID].realFirstName =
          chariteerInfo.realFirstName;
        state.chariteerDictionary[chariteerInfo.playFabID].realLastName =
          chariteerInfo.realLastName;
        state.chariteerDictionary[chariteerInfo.playFabID].realPhoneNumber =
          chariteerInfo.realPhoneNumber;
      }
    },
    ADD_CHARITEER_EMAIL_TO_DICTIONARY(state, chariteerInfo) {
      // console.log(chariteerInfo.playFabID + " has an email to be added to the Chariteer Dictionary. ")

      if (state.chariteerDictionary[chariteerInfo.playFabID] == undefined) {
        // Define it!
        state.chariteerDictionary[chariteerInfo.playFabID] = chariteerInfo;
        state.chariteerDictionaryLength++;
      } else {
        // Very likely we already have some names
        // Only set the Email
        // console.log(chariteerInfo.playFabID + " is aleady in the dictionary, adding email...")
        state.chariteerDictionary[chariteerInfo.playFabID].realEmailAddress =
          chariteerInfo.realEmailAddress;
      }
    },
    ADD_CHARITEER_TITLE_DATA_TO_DICTIONARY(state, chariteerTitleData) {
      // console.log(chariteerTitleData.playFabID + " has Title Data to be added to the Chariteer Dictionary. ")

      // Crunch the CompletedQuests so we can come up with a specific number for lifetimeHoursVolunteered
      var lifetimeHoursVolunteered = 0;
      var hackHoursVolunteeredSPPrep = 0;
      var i;
      for (i = 0; i < chariteerTitleData.completedQuests.length; i++) {
        lifetimeHoursVolunteered =
          lifetimeHoursVolunteered +
          chariteerTitleData.completedQuests[i].timeCredit;

        // HACK: The Quest needs to be after a certain date, June 1st 2021, and can only be at *FOUR* Quest Providers:
        if (
          chariteerTitleData.completedQuests[i].providerID ==
            "6979BBE94C41FFA5" ||
          chariteerTitleData.completedQuests[i].providerID ==
            "847F21FAD0FFC647" ||
          chariteerTitleData.completedQuests[i].providerID ==
            "1704EC88988014EA" ||
          chariteerTitleData.completedQuests[i].providerID == "29EE0825FBFFC2C4"
        ) {
          hackHoursVolunteeredSPPrep =
            hackHoursVolunteeredSPPrep +
            chariteerTitleData.completedQuests[i].timeCredit;
        }
      }

      if (
        state.chariteerDictionary[chariteerTitleData.playFabID] == undefined
      ) {
        // Define it!
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ] = chariteerTitleData;
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ].lifetimeHoursVolunteered = lifetimeHoursVolunteered;
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ].goalHoursVolunteered = hackHoursVolunteeredSPPrep;
        state.chariteerDictionaryLength++;
      } else {
        // Only set the Title Data
        // console.log(chariteerTitleData.playFabID + " is aleady in the dictionary, adding Title Data...")
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ].completedQuests = chariteerTitleData.completedQuests;
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ].lifetimeHoursVolunteered = lifetimeHoursVolunteered;
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ].goalHoursVolunteered = hackHoursVolunteeredSPPrep;
        // console.log("Added " + state.chariteerDictionary[chariteerTitleData.playFabID].completedQuests.length + " Quests to this Chariteer's dictionary entry.")
      }
    },
    ADD_CHARITEER_GRAND_QUEST_TO_DICTIONARY(state, chariteerTitleData) {
      // This temporary function naively assumes there is only one Grand Quest in the player Inventory.

      if (
        state.chariteerDictionary[chariteerTitleData.playFabID] == undefined
      ) {
        // Define it!
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ] = chariteerTitleData;
        state.chariteerDictionaryLength++;
      } else {
        // Only set the Title Data
        // console.log(chariteerTitleData.playFabID + " is aleady in the dictionary, adding Title Data...")
        state.chariteerDictionary[
          chariteerTitleData.playFabID
        ].completedQuests = chariteerTitleData.completedQuests;
        // console.log("Added " + state.chariteerDictionary[chariteerTitleData.playFabID].completedQuests.length + " Quests to this Chariteer's dictionary entry.")
      }
    },
    ADD_CHARITEER_GROUP_ROLE_NAME_TO_DICTIONARY(state, finalChariteerInfo) {
      if (
        state.chariteerDictionary[finalChariteerInfo.playFabID] == undefined
      ) {
        // Define it!
        state.chariteerDictionary[
          finalChariteerInfo.playFabID
        ] = finalChariteerInfo;
        // console.log(finalChariteerInfo.playFabID + " is a new record! Adding Group Role Name " + finalChariteerInfo.realGroupRoleName + "...")
        state.chariteerDictionaryLength++;
      } else {
        // Only set the Group Role Name
        state.chariteerDictionary[
          finalChariteerInfo.playFabID
        ].realGroupRoleName = finalChariteerInfo.realGroupRoleName;
        // console.log(finalChariteerInfo.playFabID + " already present, adding Group Role Name " + finalChariteerInfo.realGroupRoleName + "...")
      }
    },
    PROVIDER_ALTER_SPECIFIC_QUEST_ATTENDANCE(state) {
      // console.log("Attendance changed for " + state.currentlyViewedQuest.eventTitle)
    },
    PROVIDER_VERIFY_CHARITEER_QUEST_CONTRACT(state, bundle) {
      // console.log("Someone got rewarded or punished! " + state.currentlyViewedQuest.volunteerInfo[bundle.chariteerArrayIndex].userID)
    },
    PROVIDER_ALTER_SPECIFIC_QUEST_DETAILS(state, changedQuest) {
      // console.log("Details changed for " + state.currentlyViewedQuest.eventTitle)
      state.currentlyViewedQuest.volunteerMax = changedQuest.volunteerMax;

      // Set the loading flag to false
      state.questDetailsChangesAreLoading = false;

      // Set the success popup flag to true
      state.questDetailsChangesSubmittedSuccessfully = true;
    },
    // TRACKING
    RECORD_LOG_IN_AMAZON() {
      // console.log("LOG RECORDED successfully.")
    },
    SET_CHARITEER_EXISTS(state, exists) {
      state.playerProfile.chariteerExistsInDatabase = exists;
    },
    SET_CHARITEER_IN_PLAYER_PROFILE(state, chariteerObj) {
      state.playerProfile.chariteer = chariteerObj.data.chariteer;
      console.log(state);
    }
  },
  actions: {
    generatePlayerProfile({ commit }, id) {
      // We have our Player's PlayFab ID and we need to get all the good stuff
      var profile = {
        firstName: "",
        lastName: "",
        phoneNumber: "(201) 999-1234",
        masterPlayerID: id
      };

      // Account Info for Username = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
      var getAccountInfoRequest = {
        PlayFabId: id
      };

      window.PlayFabClientSDK.GetAccountInfo(getAccountInfoRequest)
        .then(response => {
          // console.log("Get Account Info response for Player " + id + " received.")
          // console.log(response.data)
          // console.log("This profile's username is " + response.data.AccountInfo.Username)

          commit("SET_PLAYER_USERNAME", response.data.AccountInfo.Username);
        })
        .catch(error => {
          // console.log('There was an error getting the Account Info: ', error.response)
        });

      // Publisher Data for Names = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
      var playerPublisherReadOnlyRequest = {
        PlayFabId: id
      };

      window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
        playerPublisherReadOnlyRequest
      )
        .then(response => {
          // console.log("Player Publisher response for Player " + id + " received.")
          // console.log(response.data)

          profile.masterPlayerID = id;
          profile.firstName = response.data.Data.NameFirst.Value;

          // DO THEY HAVE A LAST NAME?
          if (response.data.Data.NameLast != undefined) {
            profile.lastName = response.data.Data.NameLast.Value;
            // console.log("This guy's last name is " + response.data.Data.NameLast.Value)
          } else {
            // console.log("Last name is undefined, skip")
          }

          // DO THEY HAVE A PHONE NUMBER?
          if (response.data.Data.PhoneNumber != undefined) {
            profile.phoneNumber = response.data.Data.PhoneNumber.Value;
            // console.log("This guy's phone number is " + response.data.Data.PhoneNumber.Value)
          } else {
            // console.log("Phone number is undefined, skip")
          }

          // DO THEY HAVE A DATE OF BIRTH?
          var age = -1;
          if (response.data.Data.DateOfBirth != undefined) {
            var today = new Date();
            var birthDate = new Date(response.data.Data.DateOfBirth.Value);
            age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }

            // console.log("This guy's age is " + age)
            profile.dob = response.data.Data.DateOfBirth.Value;
            profile.age = age;
          } else {
            console.log("Date of Birth is undefined, skip");
          }

          profile.accountType = response.data.Data.PlayerAccountType.Value;
          // console.log("This profile is a " + response.data.Data.PlayerAccountType.Value)

          // Initialize Chariteers with core data they need
          // TODO (achievements, questlog maybe, things we need their ID for)

          // Initialize Providers with core data they need
          if (profile.accountType == "Provider") {
            this.dispatch("downloadAllProviderQuestData", id);
            this.dispatch("downloadAllProviderReadOnlyTitleData", id);
          }

          commit("SET_PLAYER_PROFILE", profile);
        })
        .catch(error => {
          // console.log('There was an error getting the Player Publisher Read Only Data: ', error.response)
          // You need to actually do something here. If we can't determine their account type this thing goes haywire.
        });
    },
    recordPlayerEmailAddress({ commit }, email) {
      commit("SET_PLAYER_EMAIL", email);
    },
    recordPlayerEntityInformation({ commit }, entityToken) {
      commit("SET_PLAYER_ENTITY_INFORMATION", entityToken);
    },
    fetchEvents({ commit }, id) {
      // console.log("Getting the Quest List ready for Charity " + id)

      var getUserDataRequest = {
        PlayFabId: id
      };
      window.PlayFabClientSDK.GetUserData(getUserDataRequest)
        .then(response => {
          // console.log("Quests from " + id + ", name not known yet, have been refreshed.")

          // console.log(response.data)
          // console.log("Printing all Quests:" + response.data.Data.Quests.Value)

          // Get charity info and quests info separately
          var questsStringAsJSON = JSON.parse(response.data.Data.Quests.Value);
          // console.log("Charity has " + questsStringAsJSON.specialEvents.length + " PlayFab Quests.")
          // console.log("Charity has " + questsStringAsJSON.requestedItems.length + " PlayFab Requested Items.")

          // Establish who the Current Provider is:
          var currProvider = {
            charityName: questsStringAsJSON.charityName,
            charityID: questsStringAsJSON.charityID
          };
          commit("SET_PROVIDER", currProvider);

          //  This is where the magic needs to happen. In the first pass, we just took the specialEvents array and stuffed it into state.events.
          //  But if we do that, Fundraisers and Requested Items will never show up in the list. So we need to include them in the array.
          //  However, if you do THAT then you're going to have three chronologically sorted arrays in one array one after the other, which is wrong. (But, easy!)
          //  So we need a helper function called ChronoMerge that will take the specialEvents array and make a copy and then, if there is anything in either\
          //  two lists, add them into the new array at their proper place.

          // [ C H R O N O   M E R G E ]
          var newArrayWithAllThreeCategories = questsStringAsJSON.specialEvents;
          // console.log(newArrayWithAllThreeCategories)

          var i = 0;
          var itemsIteration = 0;
          var itemWasPlaced = false;
          //var moneyIteration = 0;
          var mergingDate = null;
          var listDate = null;

          if (questsStringAsJSON.requestedItems.length > 0) {
            // console.log("Placing " + questsStringAsJSON.requestedItems.length + " Requested Item(s)")

            for (
              itemsIteration = 0;
              itemsIteration < questsStringAsJSON.requestedItems.length;
              itemsIteration++
            ) {
              i = 0;
              itemWasPlaced = false;
              mergingDate = new Date(
                questsStringAsJSON.requestedItems[itemsIteration].date +
                  " " +
                  questsStringAsJSON.requestedItems[itemsIteration].startTime
              );
              // console.log(questsStringAsJSON.requestedItems[itemsIteration].eventTitle + " is on " + mergingDate)

              //  Where should this go in the new array?
              // console.log("Beginning loop of the New Array, Length " + newArrayWithAllThreeCategories.length + " to place the Requested Item \"" + questsStringAsJSON.requestedItems[itemsIteration].eventTitle + "\"");
              for (i = 0; i < newArrayWithAllThreeCategories.length; i++) {
                listDate = new Date(
                  newArrayWithAllThreeCategories[i].date +
                    " " +
                    newArrayWithAllThreeCategories[i].startTime
                );
                // console.log("Loop " + i + ", checking against " + newArrayWithAllThreeCategories[i].eventTitle + " on " + newArrayWithAllThreeCategories[i].date)

                // Go until we find something that's after this Quest
                if (mergingDate.getTime() < listDate.getTime()) {
                  // console.log(mergingDate.getTime() + " is less than " + listDate.getTime())

                  // This can go right before this Quest
                  // console.log(questsStringAsJSON.requestedItems[itemsIteration].eventTitle + " was earlier than " + newArrayWithAllThreeCategories[i].eventTitle + ". Placed just before it!")
                  newArrayWithAllThreeCategories.splice(
                    i,
                    0,
                    questsStringAsJSON.requestedItems[itemsIteration]
                  );
                  i = newArrayWithAllThreeCategories.length;
                  itemWasPlaced = true;
                }

                // Edge Case: Same Date and Time
                if (mergingDate.getTime() === listDate.getTime()) {
                  // console.log(mergingDate.getTime() + " is also " + listDate.getTime())

                  // Just putting it before for now
                  newArrayWithAllThreeCategories.splice(
                    i,
                    0,
                    questsStringAsJSON.requestedItems[itemsIteration]
                  );
                  i = newArrayWithAllThreeCategories.length;
                  itemWasPlaced = true;
                }
              }

              if (itemWasPlaced == false) {
                // This probably was later than everything and belongs on the end...
                newArrayWithAllThreeCategories.push(
                  questsStringAsJSON.requestedItems[itemsIteration]
                );
              }
            }
          }

          // console.log(newArrayWithAllThreeCategories);

          // Deprecated
          // commit('SET_EVENTS', questsStringAsJSON.specialEvents)

          // New
          commit("SET_EVENTS", newArrayWithAllThreeCategories);
        })
        .catch(error => {
          // console.log('There was an error resfreshing Quests: ', error.response)
        });
    },
    fetchEvent({ commit, getters }, id) {
      // console.log("Fetching a single Event with id " + id + "...")
      var event = getters.getQuestById(id);

      // Did that work? Did we find the event?
      if (event) {
        // This is now our currently viewed Quest
        commit("SET_EVENT", event);

        // console.log("GetQuestById worked! Found the event of id " + id)

        for (var i = 0; i < event.volunteerInfo.length; i++) {
          // We start with the default, an unknown person and an empty object
          event.volunteerInfo[i].name = "Unknown Chariteer";

          // Get basic info for this Chariteer with ACCOUNT INFO
          var getAccountInfoRequest = {
            PlayFabId: event.volunteerInfo[i].userID
          };
          window.PlayFabClientSDK.GetAccountInfo(getAccountInfoRequest)
            .then(response => {
              // console.log("Downloaded AccountInfo for " + response.data.AccountInfo.Username)
              // console.log(response)
              // console.log(response.data.AccountInfo)
              var chariteerInfo = {
                username: response.data.AccountInfo.Username,
                id: response.data.AccountInfo.PlayFabId
              };
              commit("SET_CHARITEER_NAME", chariteerInfo);
            })
            .catch(error => {
              // console.log('There was an error getting Account Info:', error.response)
            });

          // Get Personal Info for this Chariteer with PLAYER PUBLISHER DATA
          var getPlayerPublisherDataRequest = {
            PlayFabId: event.volunteerInfo[i].userID
          };
          window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
            getPlayerPublisherDataRequest
          )
            .then(response => {
              // console.log("Downloaded PPD for " + response.data.Data.NameFirst.Value + " " + response.data.Data.NameLast.Value)
              // console.log(response)
              // console.log(response.Request.PlayFabId)

              var age = -1;
              var today = new Date();
              var birthDate = new Date(response.data.Data.DateOfBirth.Value);
              age = today.getFullYear() - birthDate.getFullYear();
              var m = today.getMonth() - birthDate.getMonth();
              if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
              }

              var chariteerSensitiveContactInfo = {
                realFirstName: response.data.Data.NameFirst.Value,
                realLastName: response.data.Data.NameLast.Value,
                realPhoneNumber: response.data.Data.PhoneNumber.Value,
                realAge: age,
                id: response.Request.PlayFabId
              };

              // console.log("ChariteerSensitiveContactInfo is now " + chariteerSensitiveContactInfo.realFirstName + " " + chariteerSensitiveContactInfo.id)
              commit(
                "SET_CHARITEER_PLAYER_PUBLISHER_DATA",
                chariteerSensitiveContactInfo
              );
            })
            .catch(error => {
              // console.log('There was an error getting Player Publisher Data Info:', error.response)
            });

          // Get the Email for this Chariteer with GET PLAYER PROFILE
          var getPlayerProfileRequest = {
            PlayFabId: event.volunteerInfo[i].userID,
            ProfileConstraints: {
              ShowDisplayName: true,
              ShowContactEmailAddresses: true
            }
          };
          window.PlayFabClientSDK.GetPlayerProfile(getPlayerProfileRequest)
            .then(response => {
              // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
              // console.log(response)

              var chariteerPlayerProfileInfo = {
                realEmailAddress: "",
                id: response.Request.PlayFabId
              };

              if (
                response.data.PlayerProfile.ContactEmailAddresses != null &&
                response.data.PlayerProfile.ContactEmailAddresses.length > 0
              ) {
                // Just use the first email
                chariteerPlayerProfileInfo.realEmailAddress =
                  response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress;
              } else {
                // Set it to null so we can check later
                chariteerPlayerProfileInfo.realEmailAddress = null;
              }

              commit(
                "SET_CHARITEER_PLAYER_PROFILE_DATA",
                chariteerPlayerProfileInfo
              );
            })
            .catch(error => {
              // console.log("There was an error getting the Player Profile for someone.")
              // console.log(error)
            });

          // You can't call Mutations here, you need to do it within the responses above.
          // Annoying, I know.
        }

        //commit('SET_EVENT', event) //any reason to do this twice?
      } else {
        // console.log("We didn't already have the quest, so we'll get it manually.")
        // Here, you could grab ALL the Quests from the pantry, find the right one, and display only that.
        // To see that, watch the end of Mastering Vuex Lesson 5 (15 mins in)
      }
    },
    fetchPastEvent({ commit, getters }, id) {
      // console.log("Fetching a single Past Event with id " + id + "...")
      var event = getters.getPastQuestById(id);

      // Did that work? Did we find the event?
      if (event) {
        // This is now our currently viewed Quest
        commit("SET_EVENT", event);

        // console.log("GetPastQuestById worked! Found the event of id " + id)

        for (var i = 0; i < event.volunteerInfo.length; i++) {
          // We start with the default, an unknown person and an empty object
          event.volunteerInfo[i].name = "Unknown Chariteer";

          // Get basic info for this Chariteer with ACCOUNT INFO
          var getAccountInfoRequest = {
            PlayFabId: event.volunteerInfo[i].userID
          };
          window.PlayFabClientSDK.GetAccountInfo(getAccountInfoRequest)
            .then(response => {
              // console.log("Downloaded AccountInfo for " + response.data.AccountInfo.Username)
              // console.log(response)
              // console.log(response.data.AccountInfo)
              var chariteerInfo = {
                username: response.data.AccountInfo.Username,
                id: response.data.AccountInfo.PlayFabId
              };
              commit("SET_CHARITEER_NAME", chariteerInfo);
            })
            .catch(error => {
              // console.log('There was an error getting Account Info:', error.response)
            });

          // Get Personal Info for this Chariteer with PLAYER PUBLISHER DATA
          var getPlayerPublisherDataRequest = {
            PlayFabId: event.volunteerInfo[i].userID
          };
          window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
            getPlayerPublisherDataRequest
          )
            .then(response => {
              // console.log("Downloaded PPD for " + response.data.Data.NameFirst.Value + " " + response.data.Data.NameLast.Value)
              // console.log(response)
              // console.log(response.Request.PlayFabId)

              var age = -1;
              var today = new Date();
              var birthDate = new Date(response.data.Data.DateOfBirth.Value);
              age = today.getFullYear() - birthDate.getFullYear();
              var m = today.getMonth() - birthDate.getMonth();
              if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
              }

              var chariteerSensitiveContactInfo = {
                realFirstName: response.data.Data.NameFirst.Value,
                realLastName: response.data.Data.NameLast.Value,
                realPhoneNumber: response.data.Data.PhoneNumber.Value,
                realAge: age,
                id: response.Request.PlayFabId
              };

              // console.log("ChariteerSensitiveContactInfo is now " + chariteerSensitiveContactInfo.realFirstName + " " + chariteerSensitiveContactInfo.id)
              commit(
                "SET_CHARITEER_PLAYER_PUBLISHER_DATA",
                chariteerSensitiveContactInfo
              );
            })
            .catch(error => {
              // console.log('There was an error getting Player Publisher Data Info:', error.response)
            });

          // Get the Email for this Chariteer with GET PLAYER PROFILE
          var getPlayerProfileRequest = {
            PlayFabId: event.volunteerInfo[i].userID,
            ProfileConstraints: {
              ShowDisplayName: true,
              ShowContactEmailAddresses: true
            }
          };
          window.PlayFabClientSDK.GetPlayerProfile(getPlayerProfileRequest)
            .then(response => {
              // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
              // console.log(response)

              var chariteerPlayerProfileInfo = {
                realEmailAddress: "",
                id: response.Request.PlayFabId
              };

              if (
                response.data.PlayerProfile.ContactEmailAddresses != null &&
                response.data.PlayerProfile.ContactEmailAddresses.length > 0
              ) {
                // Just use the first email
                chariteerPlayerProfileInfo.realEmailAddress =
                  response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress;
              } else {
                // Set it to null so we can check later
                chariteerPlayerProfileInfo.realEmailAddress = null;
              }

              commit(
                "SET_CHARITEER_PLAYER_PROFILE_DATA",
                chariteerPlayerProfileInfo
              );
            })
            .catch(error => {
              // console.log("There was an error getting the Player Profile for someone.")
              // console.log(error)
            });

          // You can't call Mutations here, you need to do it within the responses above.
          // Annoying, I know.
        }

        //commit('SET_EVENT', event) //any reason to do this twice?
      } else {
        // console.log("We didn't already have the quest, so we'll get it manually.")
        // Here, you could grab ALL the Quests from the pantry, find the right one, and display only that.
        // To see that, watch the end of Mastering Vuex Lesson 5 (15 mins in)
      }
    },
    downloadCharityQuestTitleData({ commit }) {
      // Download the Title Data for Charity Quest, so we can get:
      //  ProviderList          All of the charities.
      //  MaintenanceStatus     The current website version that we should be conforming to.
      //  SpecialThanks         Our generous patrons.
      //  Milestones            All of our Achievement stuff.

      var getTitleDataRequest = {
        Keys: null
      };
      window.PlayFabClientSDK.GetTitleData(getTitleDataRequest)
        .then(response => {
          // Let's see what we've got here...
          // console.log("Got TitleData:")
          // console.log(response)

          commit("SET_MAINTENANCE_STATUS", "weem");

          /*
          var questsStringAsJSON = JSON.parse(response.data.Data.Quests.Value)
          var event = null;

          // Let's find that Quest ID
          for ( var i = 0; i < questsStringAsJSON.specialEvents.length; i++ )
          {
            if ( questsStringAsJSON.specialEvents[i].eventID == id )
            {
              event = questsStringAsJSON.specialEvents[i]
              // console.log("Located the quest with id " + id + ", it's " + questsStringAsJSON.specialEvents[i].eventTitle)
            }
          }

          if ( event != null )
          {
            commit('SET_EVENT', event)

            // using i again
            for ( i = 0; i < event.volunteerInfo.length; i++ )
            {
              event.volunteerInfo[i].name = "Unknown Chariteer"


              var getAccountInfoRequest = {
                PlayFabId: event.volunteerInfo[i].userID
              }
              window.PlayFabClientSDK.GetAccountInfo(getAccountInfoRequest)
                .then(response => {
                  console.log("Getting Account Info worked, this is... " + response.data.AccountInfo.Username)
                  // console.log(response)
                  var chariteerInfo = {
                    username: response.data.AccountInfo.Username,
                    id: response.data.AccountInfo.PlayFabId
                  }
                  commit('SET_CHARITEER_NAME', chariteerInfo )
              })
              .catch(error => {
                console.log('There was an error getting Account Info:', error.response)
              })


              // Get Personal Info for this Chariteer with PLAYER PUBLISHER DATA
              var getPlayerPublisherDataRequest = {
                PlayFabId: event.volunteerInfo[i].userID
              }
              window.PlayFabClientSDK.GetUserPublisherReadOnlyData(getPlayerPublisherDataRequest)
                .then(response => {
                  // console.log("Downloaded PPD for " + response.data.Data.NameFirst.Value + " " + response.data.Data.NameLast.Value)
                  // console.log(response)
                  // console.log(response.Request.PlayFabId)
                  
                  
                  var chariteerSensitiveContactInfo = {
                    realFirstName: response.data.Data.NameFirst.Value,
                    realLastName: response.data.Data.NameLast.Value,
                    realPhoneNumber: response.data.Data.PhoneNumber.Value,
                    realAge: 62,
                    id: response.Request.PlayFabId
                  }
                  
                  // console.log("ChariteerSensitiveContactInfo is now " + chariteerSensitiveContactInfo.realFirstName + " " + chariteerSensitiveContactInfo.id)
                  commit('SET_CHARITEER_PLAYER_PUBLISHER_DATA', chariteerSensitiveContactInfo )
              })
              .catch(error => {
                console.log('There was an error getting Player Publisher Data Info:', error.response)
              })


              // Get the Email for this Chariteer with GET PLAYER PROFILE
              var getPlayerProfileRequest = {
                PlayFabId: event.volunteerInfo[i].userID,
                ProfileConstraints: { 
                  ShowDisplayName: true,
                  ShowContactEmailAddresses: true
                }
              }
              window.PlayFabClientSDK.GetPlayerProfile(getPlayerProfileRequest)
                .then(response => {
                  // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
                  // console.log(response)

                  var chariteerPlayerProfileInfo = {
                    realEmailAddress: "",
                    id: response.Request.PlayFabId
                  } 

                  if ( response.data.PlayerProfile.ContactEmailAddresses != null && response.data.PlayerProfile.ContactEmailAddresses.length > 0 )
                  {
                    // Just use the first email
                    chariteerPlayerProfileInfo.realEmailAddress = response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress
                  }
                  else
                  {
                    // Set it to null so we can check later
                    chariteerPlayerProfileInfo.realEmailAddress = null
                  }

                  commit('SET_CHARITEER_PLAYER_PROFILE_DATA', chariteerPlayerProfileInfo )
              })
              .catch(error => {
                console.log("There was an error getting the Player Profile for someone.")
                console.log(error)
              })
            }
          }
          else
          {
            console.log("OK event is null? So we didn't find the Quest.")
          }
          */
        })
        .catch(error => {
          // console.log('Error in TitleData!', error.response)
          // console.log(error)
          // console.log(error.response)
        });
    },
    downloadNewQuestData({ commit }, id) {
      var getUserDataRequest = {
        PlayFabId: this.state.currentlyViewedQuest.providerID
      };
      window.PlayFabClientSDK.GetUserData(getUserDataRequest)
        .then(response => {
          var questsStringAsJSON = JSON.parse(response.data.Data.Quests.Value);
          var event = null;

          // Let's find that Quest ID
          for (var i = 0; i < questsStringAsJSON.specialEvents.length; i++) {
            if (questsStringAsJSON.specialEvents[i].eventID == id) {
              event = questsStringAsJSON.specialEvents[i];
              // console.log("Located the quest with id " + id + ", it's " + questsStringAsJSON.specialEvents[i].eventTitle)
            }
          }

          // Maybe it's a Requested Item?
          if (event == null) {
            for (i = 0; i < questsStringAsJSON.requestedItems.length; i++) {
              if (questsStringAsJSON.requestedItems[i].eventID == id) {
                event = questsStringAsJSON.requestedItems[i];
                // console.log("Located the quest with id " + id + ", it's " + questsStringAsJSON.requestedItems[i].eventTitle)
              }
            }
          }

          if (event != null) {
            commit("SET_EVENT", event);

            // using i again
            for (i = 0; i < event.volunteerInfo.length; i++) {
              event.volunteerInfo[i].name = "Unknown Chariteer";

              var getAccountInfoRequest = {
                PlayFabId: event.volunteerInfo[i].userID
              };
              window.PlayFabClientSDK.GetAccountInfo(getAccountInfoRequest)
                .then(response => {
                  // console.log("Getting Account Info worked, this is... " + response.data.AccountInfo.Username)
                  // console.log(response)
                  var chariteerInfo = {
                    username: response.data.AccountInfo.Username,
                    id: response.data.AccountInfo.PlayFabId
                  };
                  commit("SET_CHARITEER_NAME", chariteerInfo);
                })
                .catch(error => {
                  // console.log('There was an error getting Account Info:', error.response)
                });

              // Get Personal Info for this Chariteer with PLAYER PUBLISHER DATA
              var getPlayerPublisherDataRequest = {
                PlayFabId: event.volunteerInfo[i].userID
              };
              window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
                getPlayerPublisherDataRequest
              )
                .then(response => {
                  // console.log("Downloaded PPD for " + response.data.Data.NameFirst.Value + " " + response.data.Data.NameLast.Value)
                  // console.log(response)
                  // console.log(response.Request.PlayFabId)

                  var age;

                  if (response.data.Data.DateOfBirth.Value) {
                    age = -1;
                    var today = new Date();
                    var birthDate = new Date(
                      response.data.Data.DateOfBirth.Value
                    );
                    age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (
                      m < 0 ||
                      (m === 0 && today.getDate() < birthDate.getDate())
                    ) {
                      age--;
                    }
                    // console.log("Computed Age is " + age)
                  } else {
                    age = "Unknown";
                  }

                  var chariteerSensitiveContactInfo = {
                    realFirstName: response.data.Data.NameFirst.Value,
                    realLastName: response.data.Data.NameLast.Value,
                    realPhoneNumber: response.data.Data.PhoneNumber.Value,
                    realAge: age,
                    id: response.Request.PlayFabId
                  };

                  // console.log("ChariteerSensitiveContactInfo is now " + chariteerSensitiveContactInfo.realFirstName + " " + chariteerSensitiveContactInfo.id)
                  commit(
                    "SET_CHARITEER_PLAYER_PUBLISHER_DATA",
                    chariteerSensitiveContactInfo
                  );
                })
                .catch(error => {
                  // console.log('There was an error getting Player Publisher Data Info:', error.response)
                });

              // Get the Email for this Chariteer with GET PLAYER PROFILE
              var getPlayerProfileRequest = {
                PlayFabId: event.volunteerInfo[i].userID,
                ProfileConstraints: {
                  ShowDisplayName: true,
                  ShowContactEmailAddresses: true
                }
              };
              window.PlayFabClientSDK.GetPlayerProfile(getPlayerProfileRequest)
                .then(response => {
                  // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
                  // console.log(response)

                  var chariteerPlayerProfileInfo = {
                    realEmailAddress: "",
                    id: response.Request.PlayFabId
                  };

                  if (
                    response.data.PlayerProfile.ContactEmailAddresses != null &&
                    response.data.PlayerProfile.ContactEmailAddresses.length > 0
                  ) {
                    // Just use the first email
                    chariteerPlayerProfileInfo.realEmailAddress =
                      response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress;
                  } else {
                    // Set it to null so we can check later
                    chariteerPlayerProfileInfo.realEmailAddress = null;
                  }

                  commit(
                    "SET_CHARITEER_PLAYER_PROFILE_DATA",
                    chariteerPlayerProfileInfo
                  );
                })
                .catch(error => {
                  // console.log("There was an error getting the Player Profile for someone.")
                  // console.log(error)
                });
            }
          } else {
            // console.log("OK event is null? So we didn't find the Quest.")
          }
        })
        .catch(error => {
          // console.log('There was an error:', error.response)
        });
    },
    downloadAllProviderQuestData({ commit }, id) {
      // console.log("Downloading ALL Quests now for " + id)

      // Downloading everything from User Data for us, our charity
      var getUserDataRequest = {
        PlayFabId: id
      };
      window.PlayFabClientSDK.GetUserData(getUserDataRequest)
        .then(response => {
          // console.log("Quests from " + id + ", name not known yet, have been refreshed.")
          // console.log(response.data)

          // DID WE GET CompletedQuests?
          if (response.data.Data.CompletedQuests != undefined) {
            // console.log("Printing all Completed Quests:" + response.data.Data.CompletedQuests.Value)
            var completedQuestsStringAsJSON = JSON.parse(
              response.data.Data.CompletedQuests.Value
            );
            commit(
              "SET_ALL_PROVIDER_COMPLETED_QUESTS",
              completedQuestsStringAsJSON
            );
          } else {
            // console.log("Found no record of 'CompletedQuests', skipping...")
          }

          // DID WE GET Quests?
          if (response.data.Data.Quests != undefined) {
            // console.log("Printing all Quests:" + response.data.Data.Quests.Value)
            var questsStringAsJSON = JSON.parse(
              response.data.Data.Quests.Value
            );
            commit("SET_ALL_PROVIDER_QUESTS", questsStringAsJSON);
          } else {
            // console.log("Found no record of 'Quests', skipping...")
          }

          // DID WE GET Templates?
          if (response.data.Data.Templates != undefined) {
            // console.log("Printing all Templates:" + response.data.Data.Templates.Value)
            var templatesStringAsJSON = JSON.parse(
              response.data.Data.Templates.Value
            );
            commit("SET_ALL_PROVIDER_QUEST_TEMPLATES", templatesStringAsJSON);
          } else {
            // console.log("Found no record of 'Templates', skipping...")
          }

          // DID WE GET Locations?
          if (response.data.Data.Locations != undefined) {
            // console.log("Printing all Locations:" + response.data.Data.Locations.Value)
            var locationsStringAsJSON = JSON.parse(
              response.data.Data.Locations.Value
            );
            commit("SET_ALL_PROVIDER_QUEST_LOCATIONS", locationsStringAsJSON);
          } else {
            // console.log("Found no record of 'Locations', skipping...")
          }
        })
        .catch(error => {
          // console.log('There was an error setting Provider Quests: ', error.response)
        });
    },
    downloadAllProviderReadOnlyTitleData({ commit }, id) {
      // Downloading everything from Read Only Title Data for the Provider

      var getUserReadOnlyDataRequest = {
        PlayFabId: id,
        Keys: ["ActivityLog", "ServiceRecords", "Surveys"]
      };
      window.PlayFabClientSDK.GetUserReadOnlyData(getUserReadOnlyDataRequest)
        .then(response => {
          // console.log(response.data)

          // DID WE GET ActivityLog?
          // We're not using this yet...

          // DID WE GET ServiceRecords?
          if (response.data.Data.ServiceRecords != undefined) {
            var serviceRecordsStringAsJSON = JSON.parse(
              response.data.Data.ServiceRecords.Value
            );
            commit("SET_PROVIDER_SERVICE_RECORDS", serviceRecordsStringAsJSON);
          } else {
            // console.log("Found no record of 'ServiceRecords', skipping...")
          }

          // DID WE GET Surveys?
          if (response.data.Data.Surveys != undefined) {
            var surveysStringAsJSON = JSON.parse(
              response.data.Data.Surveys.Value
            );
            commit("SET_PROVIDER_SURVEYS", surveysStringAsJSON);
            // console.log("Surveys acquired!")
            // console.log(surveysStringAsJSON)
          } else {
            // console.log("Found no record of 'Surveys', skipping...")
          }
        })
        .catch(error => {
          // console.log('There was an error setting Read Only Title Data: ', error.response)
        });
    },
    publishAllDraftsToActiveQuests({ commit }, drafts) {
      // Time to check what's in Drafts and send it to the Internet
      // console.log("Beginning to publish " + drafts.specialEvents.length + " Drafts to Internet for " + state.playerProfile.masterPlayerID + "...");

      var cloudScriptRequest = {
        FunctionName: "websitePublishAllDraftsToActiveQuests",
        FunctionParameter: {
          providerID: this.state.playerProfile.masterPlayerID,
          draftQuests: drafts
        },
        GeneratePlayStreamEvent: true
      };

      window.PlayFabClientSDK.ExecuteCloudScript(cloudScriptRequest)
        .then(response => {
          // console.log("Executed a Cloudscript to publish some Drafts!");
          // console.log(response);
        })
        .catch(error => {
          // console.log('There was an error publishing the Drafts.', error.response)
        });
    },
    fillChariteerDictionary({ commit }) {
      // The goal is to add the first chariteer of the servicerecords to the chariteerdictionary
      // using the format that worked during testing: PlayFabID as the unique identifier key
      // inside and outside the object:
      // "GROGU": { playFabID: "GROGU", name: "Frank", last: "DiCola"},
      // "1234": { playFabID: "1234", name: "Paul", last: "DiCola"},
      // "90A12": { playFabID: "90A12", name: "Drago", last: "Winnebago"}

      //  STEP 1.   Examine our ServiceRecords data entry and pull people from that.

      var i = 0;

      if (this.state.providerServiceRecords.chariteers == null) {
        return false;
      }

      for (
        i = 0;
        i < this.state.providerServiceRecords.chariteers.length;
        i++
      ) {
        // console.log("Getting PP (RO) Data for " + this.state.providerServiceRecords.chariteers[i].chariteerName + " so (s)he can be added to the Dictionary.")

        // We don't want to check ourselves, since this is a Provider.
        if (
          this.state.providerServiceRecords.chariteers[i].chariteerID !=
          this.state.playerProfile.masterPlayerID
        ) {
          // STEP 1a. PERSONAL INFO
          // Get Personal Info for this Chariteer with PLAYER PUBLISHER READ ONLY DATA
          var getPlayerPublisherDataRequest = {
            PlayFabId: this.state.providerServiceRecords.chariteers[i]
              .chariteerID
          };
          window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
            getPlayerPublisherDataRequest
          )
            .then(response => {
              // console.log("Downloaded PP (RO) Data for " + response.data.Data.NameFirst.Value + " " + response.data.Data.NameLast.Value)
              // console.log(response)
              // console.log(response.Request.PlayFabId)

              // FOR THE DICTIONARY
              var chariteerInfoForDictionary = {
                playFabID: response.Request.PlayFabId
              };

              // CHECK FOR A FIRST NAME
              if (response.data.Data.NameFirst != undefined)
                chariteerInfoForDictionary.realFirstName =
                  response.data.Data.NameFirst.Value;
              else {
                // console.log(chariteerInfoForDictionary.playFabID + " does not have a first name.")
                chariteerInfoForDictionary.realFirstName = "Unknown";
              }

              // CHECK FOR A LAST NAME
              if (response.data.Data.NameLast != undefined)
                chariteerInfoForDictionary.realLastName =
                  response.data.Data.NameLast.Value;
              else {
                // console.log(chariteerInfoForDictionary.playFabID + " does not have a last name.")
                chariteerInfoForDictionary.realLastName = "Unknown";
              }

              // CHECK FOR A PHONE NUMBER
              if (response.data.Data.PhoneNumber != undefined)
                chariteerInfoForDictionary.realPhoneNumber =
                  "(" +
                  response.data.Data.PhoneNumber.Value.substring(0, 3) +
                  ") " +
                  response.data.Data.PhoneNumber.Value.substring(3, 6) +
                  "-" +
                  response.data.Data.PhoneNumber.Value.substring(6, 10);
              else {
                // console.log(chariteerInfoForDictionary.playFabID + " does not have a number on file.")
                chariteerInfoForDictionary.realPhoneNumber = "None";
              }

              commit(
                "ADD_CHARITEER_INFO_TO_DICTIONARY",
                chariteerInfoForDictionary
              );
            })
            .catch(error => {
              // console.log('There was an error getting Player Publisher Read Only Data for the dictionary:', error.response)
              // console.log(error)
            });

          // STEP 1b. EMAIL ADDRESS
          // Get the Email for this Chariteer with GET PLAYER PROFILE
          var getPlayerProfileRequest = {
            PlayFabId: this.state.providerServiceRecords.chariteers[i]
              .chariteerID,
            ProfileConstraints: {
              ShowDisplayName: true,
              ShowContactEmailAddresses: true
            }
          };
          window.PlayFabClientSDK.GetPlayerProfile(getPlayerProfileRequest)
            .then(response => {
              // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
              // console.log(response)

              var chariteerPlayerProfileInfo = {
                realEmailAddress: "",
                playFabID: response.Request.PlayFabId
              };

              if (
                response.data.PlayerProfile.ContactEmailAddresses != null &&
                response.data.PlayerProfile.ContactEmailAddresses.length > 0
              ) {
                // Just use the first email
                chariteerPlayerProfileInfo.realEmailAddress =
                  response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress;
              } else {
                // Set it to a bad display value
                chariteerPlayerProfileInfo.realEmailAddress =
                  "No verified email";
              }

              commit(
                "ADD_CHARITEER_EMAIL_TO_DICTIONARY",
                chariteerPlayerProfileInfo
              );
            })
            .catch(error => {
              // console.log("There was an error getting the Player Profile for someone.")
              // console.log(error)
            });

          // STEP 1c. TITLE DATA
          // Get Title Data for this Chariteer with PLAYER READ ONLY TITLE DATA
          var getUserReadOnlyDataRequest = {
            PlayFabId: this.state.providerServiceRecords.chariteers[i]
              .chariteerID,
            Keys: ["CompletedQuests"]
          };
          window.PlayFabClientSDK.GetUserReadOnlyData(
            getUserReadOnlyDataRequest
          )
            .then(response => {
              // console.log("Downloaded Title Data for " + response.Request.PlayFabId)
              // console.log(response)
              // console.log(response.data)

              var chariteerTitleDataInfo = {
                completedQuests: [],
                playFabID: response.Request.PlayFabId
              };

              // DID WE GET CompletedQuests?
              if (response.data.Data.CompletedQuests != undefined) {
                var completedQuestsStringAsJSON = JSON.parse(
                  response.data.Data.CompletedQuests.Value
                );
                // console.log("Looking at " + completedQuestsStringAsJSON.completedQuests.length + " completed Quests!")

                chariteerTitleDataInfo.completedQuests =
                  completedQuestsStringAsJSON.completedQuests;
                // console.log("Did we do it right? " + chariteerTitleDataInfo.completedQuests.length + " completed Quests?")

                commit(
                  "ADD_CHARITEER_TITLE_DATA_TO_DICTIONARY",
                  chariteerTitleDataInfo
                );
              } else {
                // console.log("Found no record of 'CompletedQuests', skipping...")
              }
            })
            .catch(error => {
              // console.log('There was an error setting Read Only Title Data for the Chariteer in the group: ', error.response)
              // console.log(error)
            });

          // STEP 1e. GROUP ROLE NAME
          var finalChariteerInfo = {
            realGroupRoleName: "Non Member",
            playFabID: this.state.providerServiceRecords.chariteers[i]
              .chariteerID
          };
          commit(
            "ADD_CHARITEER_GROUP_ROLE_NAME_TO_DICTIONARY",
            finalChariteerInfo
          );
        } else {
          // console.log("Skipping ourselves, since this is a Provider account.")
        }
      }

      // STEP 2.    Examine just ONE of our Groups and pull people from that.
      i = 0;
      // console.log("Filling Chariteer Dictionary, and it's group time! We have this many Groups: " + this.state.groupMemberships.length)

      if (this.state.groupMemberships.length > 0) {
        var listGroupMembersRequest = {
          Group: {
            Id: this.state.groupMemberships[0].Group.Id,
            Type: this.state.groupMemberships[0].Group.Type
          }
        };

        window.PlayFabGroupsSDK.ListGroupMembers(listGroupMembersRequest)
          .then(response => {
            // console.log("Provider Group members downloaded.")

            // console.log(response)

            commit("SET_PROVIDER_GROUP_MEMBERS", response.data.Members);

            // Now that we've set the Group Members, let's also add them to the Chariteer Dictionary

            if (response.data.Members.length > 0) {
              // console.log("Number of Roles: " + response.data.Members.length)

              var role = 0;
              var i = 0;

              for (role; role < response.data.Members.length; role++) {
                // console.log("Beginning " + response.data.Members[role].RoleName + ", which has " + response.data.Members[role].Members.length + " members.")

                for (
                  i = 0;
                  i < response.data.Members[role].Members.length;
                  i++
                ) {
                  // Begin gathering data, as long as this is not us!
                  if (
                    response.data.Members[role].Members[i].Lineage
                      .master_player_account.Id !=
                    this.state.playerProfile.masterPlayerID
                  ) {
                    // STEP 2a. PERSONAL INFO
                    // Get Personal Info for this Group Member with PLAYER PUBLISHER DATA
                    var getPlayerPublisherDataRequest = {
                      PlayFabId:
                        response.data.Members[role].Members[i].Lineage
                          .master_player_account.Id
                    };
                    window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
                      getPlayerPublisherDataRequest
                    )
                      .then(response => {
                        // console.log("Downloaded PPD for Group Member " + response.data.Data.NameFirst.Value)
                        // console.log(response)

                        // FOR THE DICTIONARY
                        var chariteerInfoForDictionary = {
                          playFabID: response.Request.PlayFabId
                        };

                        // CHECK FOR A FIRST NAME
                        if (response.data.Data.NameFirst != undefined)
                          chariteerInfoForDictionary.realFirstName =
                            response.data.Data.NameFirst.Value;
                        else {
                          // console.log(chariteerInfoForDictionary.playFabID + " does not have a first name.")
                          chariteerInfoForDictionary.realFirstName = "Unknown";
                        }

                        // CHECK FOR A LAST NAME
                        if (response.data.Data.NameLast != undefined)
                          chariteerInfoForDictionary.realLastName =
                            response.data.Data.NameLast.Value;
                        else {
                          // console.log(chariteerInfoForDictionary.playFabID + " does not have a last name.")
                          chariteerInfoForDictionary.realLastName = "Unknown";
                        }

                        // CHECK FOR A PHONE NUMBER
                        if (response.data.Data.PhoneNumber != undefined)
                          chariteerInfoForDictionary.realPhoneNumber =
                            "(" +
                            response.data.Data.PhoneNumber.Value.substring(
                              0,
                              3
                            ) +
                            ") " +
                            response.data.Data.PhoneNumber.Value.substring(
                              3,
                              6
                            ) +
                            "-" +
                            response.data.Data.PhoneNumber.Value.substring(
                              6,
                              10
                            );
                        else {
                          // console.log(chariteerInfoForDictionary.playFabID + " does not have a number on file.")
                          chariteerInfoForDictionary.realPhoneNumber = "None";
                        }

                        commit(
                          "ADD_CHARITEER_INFO_TO_DICTIONARY",
                          chariteerInfoForDictionary
                        );
                      })
                      .catch(error => {
                        // console.log('There was an error getting Player Publisher Data for that Group Member:', error.response)
                        // console.log(error)
                      });

                    // STEP 2b. EMAIL ADDRESS
                    // Get the Email for this Chariteer with GET PLAYER PROFILE
                    var getPlayerProfileRequest = {
                      PlayFabId:
                        response.data.Members[role].Members[i].Lineage
                          .master_player_account.Id,
                      ProfileConstraints: {
                        ShowDisplayName: true,
                        ShowContactEmailAddresses: true
                      }
                    };
                    window.PlayFabClientSDK.GetPlayerProfile(
                      getPlayerProfileRequest
                    )
                      .then(response => {
                        // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
                        // console.log(response)

                        var chariteerPlayerProfileInfo = {
                          realEmailAddress: "",
                          playFabID: response.Request.PlayFabId
                        };

                        if (
                          response.data.PlayerProfile.ContactEmailAddresses !=
                            null &&
                          response.data.PlayerProfile.ContactEmailAddresses
                            .length > 0
                        ) {
                          // Just use the first email
                          chariteerPlayerProfileInfo.realEmailAddress =
                            response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress;
                        } else {
                          // Set it to a bad display value
                          chariteerPlayerProfileInfo.realEmailAddress =
                            "No verified email";
                        }

                        commit(
                          "ADD_CHARITEER_EMAIL_TO_DICTIONARY",
                          chariteerPlayerProfileInfo
                        );
                      })
                      .catch(error => {
                        // console.log("There was an error getting the Player Profile for " + response.data.Members[role].Members[i].Lineage.master_player_account.Id)
                        // console.log(error)
                      });

                    // STEP 2c. TITLE DATA
                    // Get Title Data for this Group Member with PLAYER READ ONLY TITLE DATA
                    var getUserReadOnlyDataRequest = {
                      PlayFabId:
                        response.data.Members[role].Members[i].Lineage
                          .master_player_account.Id,
                      Keys: ["CompletedQuests"]
                    };
                    window.PlayFabClientSDK.GetUserReadOnlyData(
                      getUserReadOnlyDataRequest
                    )
                      .then(response => {
                        // console.log("Downloaded Title Data for " + response.Request.PlayFabId)
                        // console.log(response)
                        // console.log(response.data)

                        var chariteerTitleDataInfo = {
                          completedQuests: [],
                          playFabID: response.Request.PlayFabId
                        };

                        // DID WE GET CompletedQuests?
                        if (response.data.Data.CompletedQuests != undefined) {
                          var completedQuestsStringAsJSON = JSON.parse(
                            response.data.Data.CompletedQuests.Value
                          );
                          // console.log("Looking at " + completedQuestsStringAsJSON.completedQuests.length + " completed Quests!")

                          chariteerTitleDataInfo.completedQuests =
                            completedQuestsStringAsJSON.completedQuests;
                          // console.log("Did we do it right? " + chariteerTitleDataInfo.completedQuests.length + " completed Quests?")

                          commit(
                            "ADD_CHARITEER_TITLE_DATA_TO_DICTIONARY",
                            chariteerTitleDataInfo
                          );
                        } else {
                          // console.log("Found no record of 'CompletedQuests', let's set it to 0.")
                          // console.log("The length should be zero: " + chariteerTitleDataInfo.completedQuests.length )
                        }
                      })
                      .catch(error => {
                        // console.log('There was an error setting Read Only Title Data for the Chariteer in the group: ', error.response)
                        // console.log(error)
                      });

                    // STEP 2d. INVENTORY
                    // Get the Inventory for this Group Member
                    // Call a CloudScript to get the user Inventory
                    // Do all the processing in the server
                    // Send back a response that we can scrape for info and it should be exactly what we need to stuff into chariteerDictionary. Things like...
                    // How many Grand Quests are there?
                    // What's the name of each Grand Quest? The Quest ID? The giver ID? The Status? (Basically all the custom data because I don't see how I can use Item Instance ID here... I dont actually have access)

                    // TO DO

                    // STEP 2e. GROUP ROLE NAME
                    var finalChariteerInfo = {
                      realGroupRoleName: response.data.Members[role].RoleName,
                      playFabID:
                        response.data.Members[role].Members[i].Lineage
                          .master_player_account.Id
                    };

                    commit(
                      "ADD_CHARITEER_GROUP_ROLE_NAME_TO_DICTIONARY",
                      finalChariteerInfo
                    );
                  } else {
                    // console.log("Skipping " + response.data.Members[role].Members[i].Lineage.master_player_account.Id + ", this is our account!")
                  }
                }
              }
            } else {
              // console.log("There's no Roles, so there's no Members.")
            }
          })
          .catch(error => {
            // console.log('There was an error getting the group members:', error.response)
            // console.log(error)
          });
      }
      // * * * END OF FUNCTION "fillChariteerDictionary" * * * //
    },

    downloadPlayerInventory({ commit }) {
      // console.log("Downloading inventory now... ")

      var getUserInventoryRequest = {};

      window.PlayFabClientSDK.GetUserInventory(getUserInventoryRequest)
        .then(response => {
          // console.log("Inventory refreshed.")
          // console.log(response)
          var inventoryInfo = {
            charityMedallionBalance: response.data.VirtualCurrency.CM,
            items: response.data.Inventory
          };
          commit("SET_INVENTORY", inventoryInfo);
        })
        .catch(error => {
          // console.log('There was an error getting the Inventory:', error.response)
        });
    },
    downloadPlayerGroupMemberships({ commit }) {
      // console.log("Downloading group memberships now... ")

      var listMembershipRequest = {
        Entity: {
          Id: this.state.playerProfile.entityID,
          Type: this.state.playerProfile.entityType
        }
      };

      window.PlayFabGroupsSDK.ListMembership(listMembershipRequest)
        .then(response => {
          // console.log("Group membership data downloaded.")

          // console.log(response)

          commit("SET_GROUP_MEMBERSHIPS", response.data.Groups);
          if (this.state.managedProviderGroupMemberships.length > 0) {
            this.state.currentlySelectedManagedProviderGroup = this.state.managedProviderGroupMemberships[0];
            console.log(
              "setting the current group",
              this.state.currentlySelectedManagedProviderGroup
            );
          }
        })
        .catch(error => {
          // console.log('There was an error getting the group memberships:', error.response)
        });
    },
    storeChariteerCredits({ commit }, creditsObj) {
      // Store the Credits we got from the database in our profile using two dictionaries.
      // One is Completed Quests
      // The other is a dictionary of Providers and your total minutes volunteered with them (used for prerequisite counts)
      var i = 0;
      var newDictionary = {};

      for (i; i < creditsObj.data.credits.length; i++) {
        // console.log("Value is " + creditsObj.data.credits[i].value + "!");

        if (
          creditsObj.data.credits[i].type == "volunteerTime" &&
          creditsObj.data.credits[i].unit == "minutes" &&
          creditsObj.data.credits[i].value >= 0
        ) {
          // AddMinutesToProviderTotal(credits[i].quest.provider.id, credits[i].value);

          if (
            newDictionary[creditsObj.data.credits[i].quest.provider.id] ==
            undefined
          ) {
            // Define it!
            newDictionary[creditsObj.data.credits[i].quest.provider.id] =
              creditsObj.data.credits[i].value;
          } else {
            // Add to the total
            let totalMinutes =
              newDictionary[creditsObj.data.credits[i].quest.provider.id] +
              creditsObj.data.credits[i].value;
            newDictionary[
              creditsObj.data.credits[i].quest.provider.id
            ] = totalMinutes;
          }
        }
      }

      // commit('SET_CHARITEER_COMPLETED_QUESTS', creditsObj);
      commit("SET_CHARITEER_PROGRESS_WITH_INDIVIDUAL_PROVIDERS", newDictionary);
    },

    // GROUPS
    downloadGroupMembers({ commit }, groupObj) {
      // console.log("Downloading group members for " + groupObj.Id + " which is a " + groupObj.Type)

      var listGroupMembersRequest = {
        Group: {
          Id: groupObj.Id,
          Type: groupObj.Type
        }
      };

      window.PlayFabGroupsSDK.ListGroupMembers(listGroupMembersRequest)
        .then(response => {
          // console.log("Group members downloaded.")

          // console.log(response)

          commit("SET_GROUP_MEMBERS", response.data.Members);

          // Now that we've set the Group Members, we can dig up specific info about each Group Member to make it easy to display things later.
          // Do we actually have anyone in this group though?

          if (response.data.Members.length > 0) {
            // console.log("This group indeed has " + response.data.Members.length + " Roles.")

            var role = 0;
            var i = 0;

            for (role; role < response.data.Members.length; role++) {
              // We get the Player Publisher Data
              for (i = 0; i < response.data.Members[role].Members.length; i++) {
                // Get Personal Info for this Group Member with PLAYER PUBLISHER DATA
                var getPlayerPublisherDataRequest = {
                  PlayFabId:
                    response.data.Members[role].Members[i].Lineage
                      .master_player_account.Id
                };
                window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
                  getPlayerPublisherDataRequest
                )
                  .then(response => {
                    // console.log("Downloaded PPD for Group Member " + response.data.Data.NameFirst.Value)
                    // console.log(response)

                    // Check that we actually got the data we need
                    // TODO

                    var dataBlob = {
                      ID: response.Request.PlayFabId
                    };

                    if (response.data.Data.NameFirst != null)
                      dataBlob.firstName = response.data.Data.NameFirst.Value;
                    else dataBlob.firstName = "";

                    if (response.data.Data.NameLast != null)
                      dataBlob.lastName = response.data.Data.NameLast.Value;
                    else dataBlob.lastName = "";

                    // console.log("Blob: " + dataBlob.ID + " " + dataBlob.firstName + " " + dataBlob.lastName)

                    commit("SET_GROUP_MEMBER_PLAYER_PUBLISHER_DATA", dataBlob);
                  })
                  .catch(error => {
                    // console.log('There was an error getting Player Publisher Data for that Group Member:', error.response)
                  });
              }
            }
          } else {
            // console.log("There's no Roles, so there's no Members.")
          }
        })
        .catch(error => {
          // console.log('There was an error getting the group members:', error.response)
        });
    },
    downloadGroupApplications({ commit }, groupObj) {
      // console.log("Downloading group applications for " + groupObj.Id + " which is a " + groupObj.Type)

      // The Request Body
      var listGroupApplicationsRequest = {
        Group: {
          Id: groupObj.Id,
          Type: groupObj.Type
        }
      };

      window.PlayFabGroupsSDK.ListGroupApplications(
        listGroupApplicationsRequest
      )
        .then(response => {
          // console.log("Group applications downloaded.")

          // console.log(response)

          commit("SET_GROUP_APPLICATIONS", response.data.Applications);

          // Now that we've set the Group Applications, we can dig up specific info about each Applicant to make it easy to display things later.
          // Do we actually have any Applicants?

          // I think I only need one loop here because this is not an array-inside-an-array situation
          if (response.data.Applications.length > 0) {
            // console.log("This group has " + response.data.Applications.length + " Applications.")

            var i = 0;

            // We get the Player Publisher Data
            for (i = 0; i < response.data.Applications.length; i++) {
              // Get Personal Info for this Applicant with PLAYER PUBLISHER DATA
              var getPlayerPublisherDataRequest = {
                PlayFabId:
                  response.data.Applications[i].Entity.Lineage
                    .master_player_account.Id
              };
              window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
                getPlayerPublisherDataRequest
              )
                .then(response => {
                  // console.log("Downloaded PPD for Applicant " + response.data.Data.NameFirst.Value)
                  // console.log(response)

                  // Check that we actually got the data we need
                  var dataBlob = {
                    ID: response.Request.PlayFabId
                  };

                  if (response.data.Data.NameFirst != null)
                    dataBlob.firstName = response.data.Data.NameFirst.Value;
                  else dataBlob.firstName = "";

                  if (response.data.Data.NameLast != null)
                    dataBlob.lastName = response.data.Data.NameLast.Value;
                  else dataBlob.lastName = "";

                  // console.log("Blob: " + dataBlob.ID + " " + dataBlob.firstName + " " + dataBlob.lastName)

                  commit("SET_GROUP_APPLICANT_PLAYER_PUBLISHER_DATA", dataBlob);
                })
                .catch(error => {
                  // console.log('There was an error getting Player Publisher Data for that Applicant:', error.response)
                });
            }
          } else {
            // console.log("There's no Applications to this group.")
          }
        })
        .catch(error => {
          // console.log('There was an error getting the group applications:', error.response)
        });
    },
    acceptApplicationToGroup({ commit }, applicantEntityKey) {
      // console.log("Accepting " + applicantEntityKey.Id)

      var acceptGroupApplicationRequest = {
        Entity: {
          Id: applicantEntityKey.Id,
          Type: applicantEntityKey.Type
        },
        Group: {
          Id: this.state.currentlyViewedGroup.Group.Id,
          Type: this.state.currentlyViewedGroup.Group.Type
        }
      };

      window.PlayFabGroupsSDK.AcceptGroupApplication(
        acceptGroupApplicationRequest
      )
        .then(response => {
          // console.log("The application was approved!")

          // console.log(response)
          commit("ACCEPT_APPLICATION_TO_GROUP", applicantEntityKey);

          // EXECUTE A CLOUDSCRIPT TO SEND AN EMAIL - - - - - - - - - -
          // console.log("Time to email " + applicantEntityKey.playFabID)
          var cloudScriptRequest = {
            FunctionName:
              "sendAlertEmailsAfterChariteerGroupMembershipApplicationIsApproved",
            FunctionParameter: {
              acceptedPlayerID: applicantEntityKey.playFabID
            },
            GeneratePlayStreamEvent: true
          };

          window.PlayFabClientSDK.ExecuteCloudScript(cloudScriptRequest)
            .then(response => {
              // console.log("Executed a cloudscript to send some email!")
              // console.log(response)
            })
            .catch(error => {
              // console.log('There was an error sending the Email to notify the user about their accepted Application.', error.response)
            });
          // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        })
        .catch(error => {
          // console.log('There was an error accepting the Group Application.', error.response)
        });
    },
    changeGroupRoleForSingleUser({ commit }, bundle) {
      // console.log("Changing single user from Role " + bundle.originRoleID + " to Role " + bundle.destinationRoleID)

      // Form the Request
      var changeMemberRoleRequest = {
        Group: {
          Id: this.state.currentlyViewedGroup.Group.Id,
          Type: this.state.currentlyViewedGroup.Group.Type
        },
        Members: [
          {
            Id: bundle.singleUserEntityKey.Id,
            Type: bundle.singleUserEntityKey.Type
          }
        ],
        OriginRoleId: bundle.originRoleID,
        DestinationRoleId: bundle.destinationRoleID
      };

      // Call the API
      window.PlayFabGroupsSDK.ChangeMemberRole(changeMemberRoleRequest)
        .then(response => {
          // console.log("Role changed successfully.")
          // console.log(response)

          commit("CHANGE_GROUP_ROLE_FOR_SINGLE_USER", bundle);
        })
        .catch(error => {
          // console.log('There was an error changing the Role of this single user.', error.response)
          // console.log(error)
        });
    },

    // NOT SURE WHAT THESE FOUR ARE - SORT THEM
    fetchChariteerUsername({ commit }, id) {
      // console.log("Go, get a user's username, and return it.")

      // console.log("id is " + id)
      var name = "Chubbis";

      commit("SET_EVENT", name);
    },
    signUpToVolunteerForQuest({ commit }) {
      // console.log("Time to sign up for " + this.state.currentlyViewedQuest.eventTitle + " (" + this.state.currentlyViewedQuest.eventID + ")")

      // This was easier than trying to just stuff it into FunctionParameter
      var functionParams = {
        charityID: this.state.currentlyViewedQuest.providerID,
        questID: this.state.currentlyViewedQuest.eventID,
        questName: this.state.currentlyViewedQuest.eventTitle
      };

      var cloudScriptRequest = {
        FunctionName: "signVolunteerUpForQuest",
        FunctionParameter: functionParams,
        GeneratePlayStreamEvent: true
      };
      window.PlayFabClientSDK.ExecuteCloudScript(cloudScriptRequest)
        .then(response => {
          // console.log("Sign up for Quest " + response.Request.FunctionParameter.questID + " of " + response.Request.FunctionParameter.charityID + " succeeded.")
          // console.log(response)

          // Error Checking
          var isActuallySuccessfulSignup = true; // Assume it worked until proven otherwise

          if (response.data.Logs.length > 0) {
            // console.log("Yeah I got logs. " + response.data.Logs.length + " of 'em.")

            for (var i = 0; i < response.data.Logs.length; i++) {
              if (response.data.Logs[i].Level == "Error") {
                // console.log("We got an error here!")
                isActuallySuccessfulSignup = false;

                switch (response.data.Logs[i].Message) {
                  case "ERROR-00":
                    alert("Not sure what this error is.");
                    break;
                  case "ERROR-01":
                    alert("Not sure what this error is.");
                    break;
                  case "ERROR-02":
                    alert(
                      "Sorry, this Quest is now at capacity, and we could not sign you up."
                    );
                    break;
                  case "ERROR-03":
                    alert(
                      "The Quest you're trying to sign up for no longer exists. Maybe it ended already?"
                    );
                    // I really think we should return this person to the CharityList now...
                    // TODO
                    // How about if the POP-UP that appears has a button "return me to QuestList" and it handles this thing?
                    // This window lives in QuestDetails and can handle routing
                    // From here, we just need to alter the state so this pop-up materializes
                    break;
                  case "ERROR-04":
                    alert("You're already signed up for this Quest!");
                    break;
                }
              }
            }
          }

          if (isActuallySuccessfulSignup) {
            commit("SIGN_UP_FOR_QUEST");
          }

          // We refresh our data no matter what happens.
          this.dispatch(
            "downloadNewQuestData",
            this.state.currentlyViewedQuest.eventID
          );
          this.dispatch("downloadPlayerInventory");
        })
        .catch(error => {
          // console.log('There was an error with CloudScript, tell the user!', error.response)
          // console.log(error)
        });
    },
    bailOutOfQuest({ commit, getters }) {
      // console.log("Time to bail out of " + this.state.currentlyViewedQuest.eventTitle + " (" + this.state.currentlyViewedQuest.eventID + ")")

      var contractItem = getters.getContractItemByQuestId(
        this.state.currentlyViewedQuest.eventID,
        this.state.currentlyViewedQuest.providerID
      );
      // try to find the contract, otherwise panic

      if (contractItem) {
        // console.log("Bailing on the Quest now using Contract " + contractItem.ItemInstanceId)

        var functionParams = {
          charityID: this.state.currentlyViewedQuest.providerID,
          questID: this.state.currentlyViewedQuest.eventID,
          questName: this.state.currentlyViewedQuest.eventTitle,
          itemInstanceID: contractItem.ItemInstanceId
        };

        var cloudScriptRequest = {
          FunctionName: "bailVolunteerFromQuest",
          FunctionParameter: functionParams,
          GeneratePlayStreamEvent: true
        };
        window.PlayFabClientSDK.ExecuteCloudScript(cloudScriptRequest)
          .then(response => {
            // console.log("Bailing out is 50% complete. " + response.Request.FunctionParameter.itemInstanceID + " still exists.")
            // console.log(response)

            // Error Checking
            var isActuallySuccessfulBailOut = true; // Assume it worked until proven otherwise

            if (response.data.Logs.length > 0) {
              // console.log("Bailing out has " + response.data.Logs.length + " logs.")

              for (var i = 0; i < response.data.Logs.length; i++) {
                if (response.data.Logs[i].Level == "Error") {
                  // console.log("We got an error here!")
                  isActuallySuccessfulBailOut = false;

                  switch (response.data.Logs[i].Message) {
                    case "ERROR-00":
                      alert("Not sure what this error is.");
                      break;
                    case "ERROR-01":
                      alert("Not sure what this error is.");
                      break;
                    case "ERROR-02":
                      alert(
                        "You're not signed up for this Quest, so you can't drop out."
                      );
                      break;
                    case "ERROR-03":
                      alert(
                        "The Quest you're trying to drop out of no longer exists. Maybe it ended already?"
                      );
                      // Again, we should return this person to the CharityList now...
                      break;
                  }
                }
              }
            }

            // Now that Errors have been checked...
            if (isActuallySuccessfulBailOut) {
              commit("BAIL_FROM_QUEST");

              // If we don't do this, we get hanging Contracts in the Inventory with 1 life left
              // We only proceed because we know the preceding function worked.

              var writeEventRequest = {
                EventName: "chariteer_bail_from_quest",
                Body: { InstanceId: contractItem.ItemInstanceId }
              };
              window.PlayFabClientSDK.WritePlayerEvent(writeEventRequest)
                .then(response => {
                  // console.log("Bailing out is 100% complete. " + response.Request.Body.InstanceId + " destroyed.")
                  // console.log(response)
                })
                .catch(error => {
                  // console.log("Writing didn't work either? Fuck!")
                  // console.log(error)
                });
            }

            // We refresh our data no matter what happens.
            this.dispatch(
              "downloadNewQuestData",
              this.state.currentlyViewedQuest.eventID
            );
            this.dispatch("downloadPlayerInventory");
          })
          .catch(error => {
            // console.log('There was an error with CloudScript, tell the user!', error.response)
          });
      } else {
        // console.log("The item couldn't be found. Panic!")
      }
    },
    submitApplication({ commit }) {
      // console.log("Action for submitting application to " + this.state.currentlyViewedQuest.eventTitle)

      // The only thing I have now is applying to GROUPS so this is kind of hardcoded
      var applyToGroupRequest = {
        Group: {
          Id: this.state.currentlyViewedQuest.applicationAction.groupID,
          Type: "group"
        },
        AutoAcceptOutstandingInvite: true,
        Entity: {
          Id: this.state.playerProfile.entityID,
          Type: this.state.playerProfile.entityType
        }
      };

      window.PlayFabGroupsSDK.ApplyToGroup(applyToGroupRequest)
        .then(response => {
          // console.log("User has applied to group!")

          // console.log(response)

          commit("SUBMIT_APPLICATION");
        })
        .catch(error => {
          // console.log('There was an error accepting the Group Application.', error.response)
          // console.log(error)
          // console.log(error.response)

          // You're supposed to do this in mutaitons, not here:
          this.state.questApplicationIsLoading = false;

          // show an error popup that's closeable
          this.state.questApplicationErrorCode = error.errorCode;
        });
    },
    // PROVIDERS
    providerAlterSpecificQuestAttendance({ commit }, ghostQuest) {
      // This function will make use of the currentlyViewedQuest to change the marked attendance of Chariteers on the server.
      // ...
      // console.log("Action begun for altering the Quest Attendance of " + this.state.currentlyViewedQuest.eventTitle)
      // console.log("The Ghost")
      // console.log(ghostQuest)

      // Set up Function Params with the name that CloudScript expects
      var functionParams = {
        newQuestInfo: ghostQuest
      };

      // Execute CloudScript
      var cloudScriptRequest = {
        FunctionName: "providerAlterSpecificQuestAttendance",
        FunctionParameter: functionParams,
        GeneratePlayStreamEvent: true
      };
      window.PlayFabClientSDK.ExecuteCloudScript(cloudScriptRequest)
        .then(response => {
          // console.log(response)

          if (response.data.Logs.length > 0) {
            // console.log("Attendance has " + response.data.Logs.length + " logs.")

            for (var i = 0; i < response.data.Logs.length; i++) {
              if (response.data.Logs[i].Level == "Error") {
                // console.log("We got an error here!")

                switch (response.data.Logs[i].Message) {
                  case "ERROR-03":
                    alert("The Quest you're trying to alter no longer exists.");
                    break;
                }
              }
            }
          }

          // Now that Errors have been checked... We refresh our data no matter what happens.
          this.dispatch(
            "downloadNewQuestData",
            this.state.currentlyViewedQuest.eventID
          );

          // MISSION ACCOMPLISHED
          commit("PROVIDER_ALTER_SPECIFIC_QUEST_ATTENDANCE");
        })
        .catch(error => {
          // console.log('There was an error with marking attendance via CloudScript, tell the user!', error.response)
        });
    },
    providerVerifyChariteerQuestContract({ commit }, bundle) {
      // This function will make use of the currentlyViewedQuest to reward or punish a Chariteer on the server
      // Bundle is just our Ghost Quest with an additional chariteerArrayIndex, a value we will bring with us into the server to help us

      // console.log("Num is " + bundle.chariteerArrayIndex)
      // console.log("Action begun for verifying the Quest Contract of " + this.state.currentlyViewedQuest.volunteerInfo[bundle.chariteerArrayIndex].userID)
      // console.log("The Ghost")
      // console.log(bundle)

      // Set up Function Params with the name that CloudScript expects
      var functionParams = {
        newQuestInfo: bundle
      };

      // Execute CloudScript
      var cloudScriptRequest = {
        FunctionName: "providerVerifyChariteerQuestContract",
        FunctionParameter: functionParams,
        GeneratePlayStreamEvent: true
      };
      window.PlayFabClientSDK.ExecuteCloudScript(cloudScriptRequest)
        .then(response => {
          // console.log(response)

          if (response.data.Logs.length > 0) {
            // console.log("Verification has " + response.data.Logs.length + " logs.")

            for (var i = 0; i < response.data.Logs.length; i++) {
              if (response.data.Logs[i].Level == "Error") {
                // console.log("We got an error here!")

                switch (response.data.Logs[i].Message) {
                  case "ERROR-03":
                    alert("The Quest you're trying to alter no longer exists.");
                    break;
                }
              }
            }
          }

          // MISSION ACCOMPLISHED
          commit("PROVIDER_VERIFY_CHARITEER_QUEST_CONTRACT", bundle);
        })
        .catch(error => {
          // console.log('There was an error with verifying attendance via CloudScript, tell the user!', error.response)
        });
    },
    providerAlterSpecificQuestDetails({ commit }, changedQuest) {
      // This function will make use of the currentlyViewedQuest to change the Volunteer Max of this Quest on the server.
      // console.log("Action begun for altering the Quest Details of " + this.state.currentlyViewedQuest.eventTitle)
      // console.log("The new volunteer max will be " + changedQuest.volunteerMax)

      // Set up Function Params with the name that CloudScript expects
      var functionParams = {
        newQuestInfo: changedQuest
      };

      // Execute CloudScript
      var cloudScriptRequest = {
        FunctionName: "providerAlterSpecificQuestDetails",
        FunctionParameter: functionParams,
        GeneratePlayStreamEvent: true
      };
      window.PlayFabClientSDK.ExecuteCloudScript(cloudScriptRequest)
        .then(response => {
          // console.log(response)

          if (response.data.Logs.length > 0) {
            // console.log("Editing Quest Details had " + response.data.Logs.length + " logs.")

            for (var i = 0; i < response.data.Logs.length; i++) {
              if (response.data.Logs[i].Level == "Error") {
                // console.log("We got an error here!")

                switch (response.data.Logs[i].Message) {
                  case "ERROR-03":
                    alert("The Quest you're trying to alter no longer exists.");
                    break;
                }
              }
            }
          }

          // Now that Errors have been checked... We refresh our data no matter what happens.
          this.dispatch(
            "downloadNewQuestData",
            this.state.currentlyViewedQuest.eventID
          );

          // MISSION ACCOMPLISHED
          commit("PROVIDER_ALTER_SPECIFIC_QUEST_DETAILS", changedQuest);
        })
        .catch(error => {
          // console.log('There was an error with changing Quest Details via CloudScript, tell the user!', error.response)
        });
    },

    // TRACKING
    clickTrackLogForAmazon({ commit }, tagsObj) {
      // console.log("Creating an Amazon Log for " + tagsObj.messageType)

      var amazonBody = {};
      var writeClientPlayerEventRequest = {};

      // SPONSORS
      if (
        tagsObj.messageType ==
        "Chariteer Clicks Sponsor's Banner In Charity List"
      ) {
        console.log("Tracking...");
        amazonBody = createDictionaryForAmazonClickTracking(
          amazonBody,
          "Sponsor",
          this.state.playerProfile,
          null
        );
        writeClientPlayerEventRequest.EventName =
          "chariteer_clicks_sponsor_banner_in_charity_list";
      } else if (
        tagsObj.messageType == "Chariteer Clicks Sponsor's Banner In Quest"
      ) {
        amazonBody = createDictionaryForAmazonClickTracking(
          amazonBody,
          "Sponsor",
          this.state.playerProfile,
          null
        );
        writeClientPlayerEventRequest.EventName =
          "chariteer_clicks_sponsor_banner_in_quest";
      } else if (tagsObj.messageType == "Chariteer Clicks Sponsor's Offer") {
        console.log("Tracking...");
        amazonBody = createDictionaryForAmazonClickTracking(
          amazonBody,
          "Sponsor",
          this.state.playerProfile,
          null
        );
        writeClientPlayerEventRequest.EventName =
          "chariteer_clicks_sponsor_offer";
      }

      // CHARITIES
      else if (
        tagsObj.messageType == "Chariteer Clicks Charity To View All Quests"
      ) {
        amazonBody = createDictionaryForAmazonClickTracking(
          amazonBody,
          "Charity",
          this.state.playerProfile,
          tagsObj
        );
        writeClientPlayerEventRequest.EventName =
          "chariteer_clicks_charity_to_view_all_quests";
      }
      // QUESTS
      else if (tagsObj.messageType == "Chariteer Views Quest") {
        amazonBody = createDictionaryForAmazonClickTracking(
          amazonBody,
          "Quest",
          this.state.playerProfile,
          tagsObj
        );
        writeClientPlayerEventRequest.EventName = "chariteer_views_quest";
      } else if (tagsObj.messageType == "Chariteer Clicks Volunteer Button") {
        amazonBody = createDictionaryForAmazonClickTracking(
          amazonBody,
          "Quest",
          this.state.playerProfile,
          tagsObj
        );
        writeClientPlayerEventRequest.EventName =
          "chariteer_clicks_volunteer_button";
      } else if (tagsObj.messageType == "Chariteer Clicks Bail Button") {
        amazonBody = createDictionaryForAmazonClickTracking(
          amazonBody,
          "Quest",
          this.state.playerProfile,
          tagsObj
        );
        writeClientPlayerEventRequest.EventName =
          "chariteer_clicks_bail_button";
      }

      // Wrap it up
      writeClientPlayerEventRequest.Body = amazonBody;

      window.PlayFabClientSDK.WritePlayerEvent(writeClientPlayerEventRequest)
        .then(response => {
          // console.log("Amazon log recorded successfully: " + response)
          commit("RECORD_LOG_IN_AMAZON");
        })
        .catch(error => {
          // console.log('There was an error creating an Amazon log: ', error.response)
        });
    },
    // Function to check if the Chariteer exists in the database
    async checkIfChariteerIsInDatabase({ commit, state }, thePlayerId) {
      console.log(state);
      console.log(state.playerProfile);
      console.log(state.playerProfile.masterPlayerID);
      console.log(thePlayerId);
      try {
        const response = await fetch(process.env.VUE_APP_DATABASE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: state.playerProfile.userAuthorizationToken
          },
          body: JSON.stringify({
            query: `
              query GetChariteerById {
                chariteer(id: "${thePlayerId}") {
                  id
                  username
                  nameFirst
                  nameMiddle
                  nameLast
                  playerAccountType
                  subscriptionStatus
                  contracts {
                    id
                    expectedArrivalAt
                    expectedDepartureAt
                    actualArrivalAt
                    actualDepartureAt
                    status
                  }
                  emailAddress
                  dateOfBirth
                  favoriteDistricts {
                    id
                  }
                  startedOrientations {
                    id
                    providerId
                    templateId
                    name
                    description
                    startedAt
                    expiredAt
                    resolution
                    url
                    note
                  }
                }
              } 
            `
          })
        });
        const data = await response.json();
        if (data.errors && data.errors.length > 0 && data.errors[0].extensions.code === 404) {
          commit('SET_CHARITEER_EXISTS', false);
        } else {
          console.log(thePlayerId + " exists in the Database.");
          commit('SET_CHARITEER_EXISTS', true);
          commit('SET_CHARITEER_IN_PLAYER_PROFILE', data);
        }
      } catch (error) {
        console.error('Error checking Chariteer in database:', error);
        commit('SET_CHARITEER_EXISTS', false);
      }
    },
  },
  getters: {
    getQuestById: state => id => {
      // console.log("Does a Quest ID " + id + " exist in Events?")

      return state.events.find(event => event.eventID == id);
    },
    getPastQuestById: state => id => {
      // console.log("Does a Past Quest ID " + id + " exist in Completed Quests?")

      return state.providerCompletedQuests.specialEvents.find(
        event => event.eventID == id
      );
    },
    getContractItemByQuestId: state => (id, charity) => {
      // console.log("Do we have a contract for Quest ID " + id + " from Charity " + charity)
      // console.log("This many contracts: " + state.inventoryContracts.length)
      for (var i = 0; i < state.inventoryContracts.length; i++) {
        // console.log("Checking item from " + state.inventoryContracts[i].CustomData.charityID + " with id " + state.inventoryContracts[i].CustomData.questID)

        if (
          state.inventoryContracts[i].CustomData.charityID == charity &&
          state.inventoryContracts[i].CustomData.questID == id
        ) {
          // console.log("Contract found!")
          return state.inventoryContracts[i];
        }
      }

      // Return null if we don't find it
      return null;
    },
    sortDictionary: state => {
      const dictionary = state.chariteerDictionary;
      const array = Object.values(dictionary);
      const sorted = array.slice().sort((a, b) => {
        if (a["realGroupRoleName"] < b["realGroupRoleName"]) {
          return -1;
        }
        if (a["realGroupRoleName"] > b["realGroupRoleName"]) {
          return 1;
        }
        return b.goalHoursVolunteered - a.goalHoursVolunteered;
      });

      return sorted;
    }
  },
  modules: {}
});

function createDictionaryForAmazonClickTracking(
  dict,
  castType,
  playerProfile,
  tagsObj
) {
  // console.log("Creating Amazon dictionary for " + playerProfile.username + " (" + playerProfile.emailAddress + ")")

  // Step 1.  Everyone needs source data about the browser, device, and OS
  dict = {
    userBrowser: "website",
    userDeviceModel: "computer"
  };

  // Desktop vs. Handheld
  // TODO

  // Operating System
  // TODO

  // Step 2.  What kind of tags do we need to add?
  if (castType == "Basic") {
    // Take no special action
  } else if (castType == "Quest") {
    // console.log("Queest!")
    dict.questProviderName = tagsObj.providerName;
    dict.questProviderID = tagsObj.providerID;
    dict.questTitle = tagsObj.eventTitle;
    dict.questID = tagsObj.eventID;
    dict.questType = tagsObj.eventType;
    dict.questDescription1 = tagsObj.description1;
    dict.questDescription2 = tagsObj.description2;
    dict.questDescription3 = tagsObj.description3;
    dict.questGiver = tagsObj.giverName;
    dict.questEquipment = tagsObj.equipment;
    dict.questDate = tagsObj.date;
    dict.questStartTime = tagsObj.startTime;
    dict.questEndTime = tagsObj.endTime;
    dict.questReward = tagsObj.timeCredit / 60;
    dict.questLocationName = tagsObj.locationName;
    dict.questLocationLatitude = tagsObj.locationLatitude;
    dict.questLocationLongitude = tagsObj.locationLongitude;
    dict.questVolunteerMax = tagsObj.volunteerMax;
    dict.questCurrentVolunteerCount = tagsObj.volunteerInfo.length;
    dict.questAgeRange = tagsObj.ageRange;
    dict.questURL = tagsObj.url;
  } else if (castType == "Charity") {
    dict.charityID = tagsObj.charityID;
    dict.charityName = tagsObj.charityName;
  } else if (castType == "Sponsor") {
    /*
      WorldSponsor s = (WorldSponsor)obj;
      int age = DetermineIntegerAge(userDOB, System.DateTime.Today);
      dict.Add("userAge", age);
    */

    dict.sponsorName = "RightAngle";
    dict.userScreenName = playerProfile.username;
    dict.userRealName = playerProfile.firstName + " " + playerProfile.lastName;
    dict.userEmailAddress = playerProfile.emailAddress;
    // TODO dict.userAge (because I don't even have Player Publisher Data in this yet)
  }

  return dict;
}
