<template>
  <!-- ... -->
  <div>
    <!-- Let's look for Search Input or Autocomplete -->
    <!-- This should really be an input field where people type and we filter the array based on what they've typed -->
    <!-- <select @change="handleSelectChange">
        <option v-for="group in databaseGuilds?.guilds" :value="group.id" :key="group.id">
          {{ group.name }}
        </option>
    </select>
    <br>
    -->
    <input type="text" placeholder="Type to select a Guild..." v-model="searchTerm">
    <ul v-if="durpySearchGuilds?.length" class="absolute bg-gray-300 m-0 p-0 pl-2 pr-2 max-w-[450px] list-none shadow-sm rounded">
      <!--
      <li class="text-sm">
        Showing {{ durpySearchGuilds?.length }} of {{ databaseGuilds?.guilds.length }} results
      </li>
      -->
      <li
        v-for="guild in durpySearchGuilds"
        :key="guild.name"
        @click="selectGuild(guild)"
        class="text-sm mt-1 mb-1 hover:bg-green-200 cursor-pointer"
      >
        {{ guild.name }}
        <!-- <button @click="(e) => handleListSelect(guild, e)">Reserve</button> -->
      </li>
    </ul>
  </div>
</template>

<script>

// We need to pull ALL the Guilds from the database so Hamis / Alyson / Rev can choose one
// from the list and reserve a Quest for that Guild. This can be more robust in the future,
// with filters for different kinds of Guilds as well as location restrictions (which makes the
// query more managable) but the naive implementation here is just "let's get every single Guild"
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import gql from 'graphql-tag'

export default {
  name: "GuildSelector",
  components: {
  },
  created() {
    console.log("Guild Selector, standing by.");
  },
  props: ['onSelection', 'questData'],
  setup(props) {

    // For the text autocompleting
    let searchTerm = ref('');

    const route = useRoute();
      
    const { result, loading, error, refetch, onResult } = useQuery(gql`
         query GetAllGuilds {
          guilds {
            id
            name
            classification
            description
            easyJoinCode
            contracts {
              questId
            }
           }
         }
       `,
       )
      
      console.log("Refetching Guilds!");
      console.log(result);
      console.log("Can we see the Guilds we refetched?");
      console.log(result.guilds);
      console.log(error);
      if (props.questData == null )
      {
         console.log("GuildSelector has no QuestData");
      }
      else
        console.log(props.questData);
       
    return {
      databaseGuilds: result || null,
      loading,
      error,
      refetch,
      onResult,
      searchTerm,
      quest: props.questData
    }
  },
  methods: {
    handleSelectChange(e) {
  		if ( this.onSelection ) {
        console.log("We switched to this Guild:");
  			this.onSelection(e.target?.value)
  		}
  	},
    handleListSelect ( group, e ) {
      // console.log("Selecting this Group:");
      // console.log(group);
      // this.onSelection(group);
    },
    selectGuild ( group )
    {
      console.log("Guild Selector has chosen the following group based on " + this.searchTerm);
      console.log(group);
      console.log("For the Quest");
      console.log(this.quest);
      this.onSelection(group, this.quest);
    }
  },
  computed: {
    durpySearchGuilds() {
      // Null check
      if (this.searchTerm === '') {
        // console.log("SearchTerm is the empty string!");
        return [];
      }
      else
      {
        // console.log("Filtering based on " + this.searchTerm);
      }

      // Filtering the Dropdown
      // console.log("Number of Guilds is " + this.databaseGuilds?.guilds.length);
      let matches = 0;
      return this.databaseGuilds?.guilds.filter(groupInfo => {
        if (
          groupInfo.name.toLowerCase().includes(this.searchTerm.toLowerCase())
          && matches < 5
        ) {
          matches++
          return groupInfo
        }
      });
    },
  }
}
</script>