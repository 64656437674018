<template>
  <div
    class="p-1 rounded flex flex-row m-1"
    :class="[
      quest.canceled ? 'bg-gray-300' : 'bg-cq-orange-100',
      viewOnly ? 'bg-gray-200 cursor-not-allowed' : ''
    ]"
  >
    <button
      @click="$emit('cancel-quest')"
      class="text-white py-1 px-4 mr-2 rounded transition text-2xl font-bold"
      :class="[
        quest.canceled
          ? 'bg-gray-400 hover:bg-gray-600'
          : 'bg-red-500 hover:bg-red-700',
        viewOnly ? 'bg-gray-400 cursor-not-allowed' : 'cursor-pointer'
      ]"
      :disabled="viewOnly"
    >
      {{ quest.canceled ? "Skipped" : "Cancel?" }}
    </button>
    <div class="mb-2 w-[10rem]">
      <p
        class="text-gray-600 text-lg overflow-hidden whitespace-nowrap text-ellipsis"
      >
        {{ quest.quest_title }}
      </p>
    </div>
    <div v-if="hasEndTime" class="flex items-center mb-2 w-[15rem]">
      <label for="start-time" class="text-gray-600 mr-2 text-lg">From</label>
      <input
        id="start-time"
        type="time"
        :value="localStartTime"
        @input="$emit('update-start-time', $event.target.value)"
        class="border border-gray-300 rounded p-1 text-sm mr-4"
        :disabled="viewOnly"
        :class="{ 'bg-gray-100 cursor-not-allowed': viewOnly }"
      />
      <label for="end-time" class="text-gray-600 mr-2 text-lg">To</label>
      <input
        id="end-time"
        type="time"
        :value="localEndTime"
        @input="$emit('update-end-time', $event.target.value)"
        class="border border-gray-300 rounded p-1 text-sm"
        :disabled="viewOnly"
        :class="{ 'bg-gray-100 cursor-not-allowed': viewOnly }"
      />
    </div>
    <div v-else class="flex items-center mb-2 w-[15rem]">
      <label for="start-time" class="text-gray-600 mr-2 text-lg">at</label>
      <input
        id="start-time"
        type="time"
        :value="localStartTime"
        @input="$emit('update-start-time', $event.target.value)"
        class="border border-gray-300 rounded p-1 text-sm"
        :disabled="viewOnly"
        :class="{ 'bg-gray-100 cursor-not-allowed': viewOnly }"
      />
    </div>
  </div>
</template>

<script>
import { convertUTCTimeStringToLocalTimeString } from "../helpers/datetimeConversions";

export default {
  props: {
    quest: Object,
    viewOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localStartTime() {
      return convertUTCTimeStringToLocalTimeString(this.quest.start_time);
    },
    localEndTime() {
      return convertUTCTimeStringToLocalTimeString(this.quest.end_time);
    },
    hasEndTime() {
      return this.quest.end_time !== null;
    }
  }
};
</script>
