<template>
  <div class="bg-cq-orange">
    <KojiClone />
  </div>
</template>

<script>
import KojiClone from "@/components/KojiClone.vue";

export default {
  name: "LinkMenu",
  components: {
    KojiClone
  }
};
</script>
