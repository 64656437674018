export const getOrientationUpdateErrorText = ( errorCode, errorMessage ) => {
    let errorText = "Unknown error updating Orientation.";

    // Missing Items
    if ( errorCode == 404 && errorMessage == "Quest Not Found" )
        errorText = "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!";
    else if ( errorCode == 404 && errorMessage == "Provider Not Found" )
        errorText = "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!";

    return errorText;
}