<template>
  <div class="bg-cq-orange text-cq-gray text-center items-center">
    <MasterLayout>
      <!-- This ad needs to be here because a local business is paying me! -->
      <FDFullWidthBanner />

      <!-- Guild Details Card -->
      <div class="bg-white rounded-2xl w-2/5 mx-auto my-10 p-5 drop-shadow-lg">
        <img
          src="../assets/quest-guild-flag.png"
          alt="Guild Flag"
          class="w-1/2"
        />
        <h1>{{ guildDetails?.getGuild?.name }}</h1>
        <p>{{ guildDetails?.getGuild?.description }}</p>
        <div v-if="currentGuildIds.includes(guildDetails?.getGuild?.id)">
          <p class="mt-10">You are a member of this guild</p>
          <div class="flex space-x-4 justify-center">
            <button
              class="border-none bg-blue-600 text-white rounded-lg py-3 px-5 hover:bg-blue-700 hover:cursor-pointer ease-in-out transition-all duration-100 drop-shadow-md hover:scale-105 hover:drop-shadow-lg hidden"
            >
              Invite Someone
            </button>
            <button
              class="border-none bg-red-600 text-white rounded-lg py-3 px-5 hover:bg-red-700 hover:cursor-pointer ease-in-out transition-all duration-100 drop-shadow-md hover:scale-105 hover:drop-shadow-lg hidden"
            >
              Leave Guild
            </button>
          </div>
        </div>
        <div v-else>
          <div class="mt-10 w-1/2 mx-auto">
            <p>
              If you know this group's join code, you may enter it below to
              join!
            </p>
            <button
              class="border-none bg-indigo-600 text-white rounded-lg py-3 px-5 hover:bg-indigo-700 hover:cursor-pointer ease-in-out transition-all duration-100 drop-shadow-md hover:scale-105 hover:drop-shadow-lg"
              @click="
                openJoinModal({
                  guildName: guildDetails?.getGuild?.name
                })
              "
            >
              Join Guild
            </button>
          </div>
        </div>
      </div>

      <!-- Quest tabs -->
      <TabsRoot default-value="upcoming" class="mb-10 w-3/5 text-left mx-auto">
        <TabsList aria-label="tabs example">
          <TabsTrigger
            value="upcoming"
            class="border-none bg-cq-orange tab-trigger"
          >
            Upcoming Quests
          </TabsTrigger>
          <TabsTrigger
            value="past"
            class="border-none bg-cq-orange tab-trigger"
          >
            Past Quests
          </TabsTrigger>
        </TabsList>
        <TabsContent value="upcoming">
          <p class="mb-10">
            Here are the upcoming quests that
            {{ guildDetails?.getGuild?.name }}
            has reserved:
          </p>
          <template
            v-if="upcomingGuildQuests && upcomingGuildQuests.length !== 0"
          >
            <div class="max-w-lg mx-auto space-y-6 pb-10">
              <DBQuestCard
                v-for="quest in upcomingGuildQuests"
                v-bind:key="quest.id"
                v-bind:quest="quest"
              />
            </div>
          </template>
          <template v-else>
            <p class="max-w-lg mx-auto text-center pb-10">
              This charity does not currently have any more Quests scheduled.<br />Please
              check back toward the end of the month for new Quests!
            </p>
          </template>
        </TabsContent>
        <TabsContent value="past">
          <p class="mb-10">
            Here are the quests that
            {{ guildDetails?.getGuild?.name }} has reserved in the past:
          </p>
          <template v-if="pastGuildQuests && pastGuildQuests.length !== 0">
            <div class="max-w-lg mx-auto space-y-6 pb-10">
              <DBQuestCard
                v-for="quest in pastGuildQuests"
                v-bind:key="quest.id"
                v-bind:quest="quest"
              />
            </div>
          </template>
          <template v-else>
            <p class="max-w-lg mx-auto text-center pb-10">
              This charity does not currently have any Quests completed.<br />Please
              check back toward the end of the month for new Quests!
            </p>
          </template>
        </TabsContent>
      </TabsRoot>

      <!-- Modal for the guild join -->
      <SecretGuildCodeModal
        v-if="showJoinModal"
        :handleTryCode="handleSubmitSecretGuildCode"
        :close="closeJoinModal"
        :groupData="guildDetails?.getGuild?.name"
        type="guild"
      />
    </MasterLayout>
  </div>
</template>

<script>
import MasterLayout from "@/components/MasterLayout.vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from "radix-vue";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import DBQuestCard from "../components/DBQuestCard.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import SecretGuildCodeModal from "../components/modals/SecretGuildCodeModal.vue";

export default {
  name: "GuildDetails",
  components: {
    MasterLayout,
    FDFullWidthBanner,
    TabsContent,
    TabsList,
    TabsRoot,
    TabsTrigger,
    DBQuestCard,
    SecretGuildCodeModal
  },
  props: ["guildID"],
  data() {
    return {
      showJoinModal: false
    };
  },
  setup() {
    const notifyError = text => {
      toast.error(text, {
        autoClose: false
      }); // ToastOptions
    };

    const notifySuccess = text => {
      toast.success(text, {
        autoClose: 10000
      }); // ToastOptions
    };

    const route = useRoute();
    const { result, loading, error, refetch } = useQuery(
      gql`
				query GetGuild {
					getGuild(id: "${route.params.guildID}") {
						id
						name
						classification
						description
						easyJoinCode
						contracts {
							id
							quest {
								id
								eventTitle
								provider {
									id
									name
								}
								resourceMaxNumber
								serviceTags
								startedAt
								endedAt
							}
							chariteerId
							claimedAt
							abandonedAt
							abandonReason
							expectedArrivalAt
							expectedDepartureAt
							status
						}
					}
				}
			`
    );

    return {
      guildDetails: result || null,
      loading,
      error,
      refetch,
      notifySuccess,
      notifyError
    };
  },
  computed: {
    currentGuildIds() {
      return this.$store.state.groupMemberships.map(group => {
        return group.Group.Id;
      });
    },
    guildQuests() {
      return (
        this.guildDetails?.getGuild?.contracts.map(contract => {
          return {
            ...contract.quest,
            providerId: contract.quest.provider.id,
            location: { name: contract.quest.provider.name },
            abandonedAt: contract.abandonedAt
          };
        }) || []
      );
    },
    pastGuildQuests() {
      return this.guildQuests.filter(quest => {
        return (
          new Date(quest.endedAt) < new Date() && quest.abandonedAt === null
        );
      });
    },
    upcomingGuildQuests() {
      return this.guildQuests.filter(quest => {
        return (
          new Date(quest.endedAt) > new Date() && quest.abandonedAt === null
        );
      });
    }
  },
  methods: {
    openJoinModal() {
      this.showJoinModal = true;
    },
    closeJoinModal() {
      this.showJoinModal = false;
    },
    handleSubmitSecretGuildCode(code) {
      // Codes any length other than 4 characters are not attempted
      if (code.length == 4) {
        console.log(
          "dev",
          this.guildDetails,
          this.guildDetails?.getGuild?.easyJoinCode
        );
        if (code == this.guildDetails?.getGuild?.easyJoinCode) {
          this.notifySuccess("Guild Code Accepted, please wait...");
          this.closeJoinModal();
          this.handleChariteerJoinsGroupViaSecretGuildCode();
        } else {
          this.notifyError(
            "Sorry, that is not the correct code for this Guild."
          );
        }
      } else {
        // Do nothing?
        console.log("Code is not 4 characters long");
      }
    },
    async handleChariteerJoinsGroupViaSecretGuildCode() {
      // we're going to submit the player's title ID and the GROUP ID
      // then, refetch. You can't just add someone to a Quest. What if it's a flex quest?

      console.log(
        "This player has this title id: " +
          this.$store.state.playerProfile.entityID
      );
      console.log(
        "This player has this title id: " +
          this.$store.state.playerProfile.entityType
      );

      // Execution
      const result = await this.mutationJoinGroupViaSecretGuildCode();

      if (result.errors?.length > 0) {
        console.log(result);
        const errorMessage = result.errors[0].extensions.response.message;

        const errorCode = result.errors[0].extensions.response.statusCode;

        // Missing Items
        if (errorCode == 404 && errorMessage == "Quest Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Provider Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the Quest Provider you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Chariteer Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the person you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        else if (errorCode == 404 && errorMessage == "Contract Not Found")
          this.notifyError(
            "ERROR! This is a glitch. For some reason, we can't find the item you're trying to interact with, but the system still allowed you to try. Sorry about that!"
          );
        // Logical Issues
        else if (
          errorCode == 406 &&
          errorMessage == "Chariteer Is Already On This Quest"
        )
          this.notifyError(
            "ERROR! This is a glitch, you're already signed up for this Quest. For some reason, the system is still letting you sign up again. Sorry about that!"
          );
        else if (errorCode == 406 && errorMessage == "Quest Is Full")
          this.notifyError(
            "ERROR! Sorry, this Quest already has enough volunteers. For some reason, the system is still letting you sign up even though it's full. Sorry about that!"
          );
        else if (
          errorCode == 410 &&
          errorMessage == "Quest Already Over, Can't Sign Up"
        )
          this.notifyError(
            "ERROR! Sorry, this Quest already ended. For some reason, the system is still letting you sign up even though it ended. Sorry about that!"
          );
        else
          this.notifyError(
            "UNKNOWN ERROR! An unexpected error occurred. Sometimes the problem is the Wi-Fi network you're using, which could be blocking Charity Quest. Try switching over just to Mobile Data and try that again. If you keep seeing this error, email me at frank@charityquest.io"
          );
      } else {
        // Let's make sure this Guild is listed in groupMemberships, come Hell or High Water.
        const refreshedGuildData = await this.$store.dispatch(
          "downloadPlayerGroupMemberships"
        );

        // UI and Data Refresh
        this.refetch();

        // Toast
        this.notifySuccess(
          "You have successfully joined your Guild! Now you can sign up for reserved Quests."
        );
      }
    },
    async mutationJoinGroupViaSecretGuildCode() {
      console.log("Adding this user to " + this.guildDetails?.getGuild?.id);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
            mutation AddChariteerToGuild ($ASCI: AddSingleChariteerInput!) {
              addChariteerToGuild(addSingleChariteer: $ASCI) {
                id
              }
            }
          `,
          variables: {
            ASCI: {
              id: this.$store.state.playerProfile.masterPlayerID,
              playFabTitleId: this.$store.state.playerProfile.entityID,
              guildId: this.guildDetails?.getGuild?.id
            }
          }
        })
      });

      const data = await result.json();
      return data;
    }
  }
};
</script>

<style>
.tab-trigger {
  cursor: pointer;
  border-radius: 8px;
}

.tab-trigger[data-state="active"] {
  text-decoration: underline;
  font-size: 36px;
  line-height: 40px;
}

.tab-trigger[data-state="inactive"] {
  font-size: 26px;
  line-height: 30px;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
</style>
