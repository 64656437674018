<template>
  <div class="chariteers">
    <TopNav/>

    <!-- This screen shows your Chariteers and allows you to view them in filtered ways. -->
    <!-- Other features like downloading contact lists, etc -->
    
    <div>
      <!-- Heading -->
      <div class="w-2/3 mx-auto text-white mt-10">
        <h2>View a list of the top volunteers at {{ this.$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderName }} to see your most valuable crew members, see their contact information, and manage their in-progress training.</h2>
      </div>

      <!-- Time Range -->
      <div class="bg-gray-100 w-2/3 mx-auto rounded-md p-4 mt-5">
        <h3 class="m-0">Displaying all volunteers from {{ topVolunteerTimeRangeStartReadableText }} to {{ topVolunteerTimeRangeEndReadableText }}</h3>
        <label for="topVolunteerTimeRangeStart">From:</label>
        <input type="date" id="topVolunteerTimeRangeStart" name="topVolunteerTimeRangeStart" v-model="rangeBegin">
        <label for="topVolunteerTimeRangeEnd">To:</label>
        <input type="date" id="topVolunteerTimeRangeEnd" name="topVolunteerTimeRangeEnd" v-model="rangeEnd">
      </div>
      <br>
      <p class="text-center font-bold text-xl">{{ this.$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderName }}</p>
      <ChariteersList v-if="isValid" :selectedManagedProviderId="this.$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderId" :topVolRangeStart="rangeBegin" :topVolRangeEnd="rangeEnd"/>
    </div>

    <Footer/>
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import ChariteersList from "@/components/ChariteersList.vue";
import Footer from "@/components/Footer.vue";


export default {
  name: "Chariteers",
  data() {
    return {
      rangeBegin: new Date("2023-01-01T00:00:00.000Z"),
      rangeEnd: new Date("2025-01-01T00:00:00.000Z")
    }
  },
  components: {
    TopNav,
    ChariteersList
  },
  methods: {},
  computed: {
    topVolunteerTimeRangeStartReadableText ()
    {
      var startTime = new Date(this.rangeBegin);
      var startTimeText = startTime.toLocaleString('en-US', { timeZone: 'UTC', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
      console.log(this.rangeBegin);
      console.log(startTimeText);

      return startTimeText;
    },
    topVolunteerTimeRangeEndReadableText ()
    {
      var endTime = this.rangeEnd
      var endTimeText = endTime.toLocaleString('en-US', { timeZone: 'UTC', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

      return endTimeText;
    },
    isValid() {
      return this.$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderId && this.rangeBegin && this.rangeBegin;
    }
  }
}
</script>

<style scoped>
.chariteers {
  background-color: #FFA94E;
  padding-bottom: 100px;
}
</style>
