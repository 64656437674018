<template>
  <!-- Flex box for the two windows at the top, side by side -->
  <div class="w-4/5 mx-auto mt-10">
    <!-- The Stats, on the Left -->
    <div class="text-left">
      <h1 class="m-0 mb-1">{{ prettyProviderName }}</h1>
      <!-- Maybe this should be a block that's changeable, because you could filter by many date ranges (month or year)-->
      <!-- Google has a dedicated TODAY button -->
      <!-- drop down for "what is this thing (Day week month year custom)"-->
      <!-- Date picker is excellent, but what I really need is two dropdowns: what is the thing, and when is the thing -->
      
      <div class="flex gap-4 mb-5">
        <!-- Time Scale Dropdown -->
        <div>
          <select v-model="selectedTimeScale" id="timeScale" class="text-xl">
            <option v-for="scale in timeScales" :key="scale" :value="scale">
              {{ scale }}
            </option>
          </select>
        </div>

        <!-- Dynamic Dropdown -->
        <div v-if="selectedTimeScale !== 'Custom Range'">
          <select v-model="selectedOption" id="dynamicOptions" class="text-xl">
            <option v-for="option in dynamicOptions" :key="option" :value="option">
              {{ option }}
              <span v-if="selectedTimeScale == 'Month'">{{ showYear(this.dateRange.dateRangeBeginning) }}</span>
            </option>
          </select>
        </div>

        <!-- <p>Preferred Year is BROKEN {{ this.preferredYear }}</p> -->

        <!-- Custom Range (Date Picker)
        <div v-else>
          <label for="customRange">Custom Range</label>
          <date-picker v-model="customRange" range />
        </div> -->
      </div>

      <!-- Debugging Output
      <p>Selected Time Scale: {{ selectedTimeScale }}</p>
      <p>Selected Option: {{ selectedOption }}</p>
      <p v-if="selectedTimeScale === 'Custom Range'">Custom Range: {{ customRange }}</p> -->


      <!-- An ugly block of stats -->
      <div class="text-xl mb-1">
        <p class="m-0">Unique volunteers in the {{ selectedTimeScale }} of {{ selectedOption }}: <span class="text-gray-700 font-bold">{{ databaseRecap?.generateSingleMonthReport?.chariteerTimeResults?.length.toLocaleString('en-US') }}</span></p>
        <p class="m-0">New volunteers in the {{ selectedTimeScale }} of {{ selectedOption }}: <span class="text-gray-700 font-bold">{{ databaseRecap?.generateSingleMonthReport?.chariteerFirstTimeResults?.length.toLocaleString('en-US') }}</span></p>
        <p v-if="this.selectedTimeScale == 'Month'" class="m-0">Volunteers retained since previous {{ selectedTimeScale }}: <span class="text-gray-700 font-bold">{{ databaseRecap?.generateSingleMonthReport?.retainedChariteerTimeResults?.length + " (" + prettyRetentionRate + "%)"}}</span></p>
        <p class="m-0">Hours of charitable service this {{ selectedTimeScale }}: <span class="text-gray-700 font-bold">{{ prettyServiceMinutes }}</span></p>
        <p class="m-0">Estimated dollar value of volunteer labor this {{ selectedTimeScale }}: <span class="text-gray-700 font-bold">{{ "$" + calculatedDollarValue }}</span></p>
        <!-- <p class="m-0">Quests with unresolved attendance this {{ selectedTimeScale }}: <span class="text-gray-700 font-bold">{{ "?" }}</span></p>
        <p class="m-0">Upcoming quests in this {{ selectedTimeScale }}: <span class="text-gray-700 font-bold">{{ "?" }}</span></p> -->
      </div>

      <!-- Row of action buttons -->
      <!-- I moved this! -->
    </div>

    <!-- Top Volunteers, on the Right -->
    <!-- Ancient Machine<div>
      <button @click="e => handleCheckListButton(e)">Check the list of Chariteers</button>
    </div> -->
  </div>

  

  <!-- Placeholder ROW for Achievements, will do later -->
  <br>
  <br>

  <!-- PANEL: All of the Volunteer Lists -->
  <div class="w-4/5 mx-auto">
    <!-- 1. All Volunteers, and their Scores -->
    <div class="bg-white text-gray-900 text-left shadow-lg rounded-lg p-5 mb-5">
      <div class="flex flex-row justify-between mb-3">
        <h2 class="m-0">All Volunteers in the {{ selectedTimeScale }} of {{ selectedOption }} ({{ databaseRecap?.generateSingleMonthReport?.chariteerTimeResults?.length }})</h2>
        <button class="bg-gray-200 hover:bg-cq-orange cursor-pointer text-gray-800 font-bold py-2 px-4 mr-3 rounded border-none inline-flex items-center" v-on:click="downloadChariteerDataAsCSVFile">Download This Recap</button>
      </div>

      <table class="w-full text-left border-collapse">
        <!-- Table Header -->
        <thead>
          <tr class="bg-white">
            <th class="p-2 text-sm font-semibold text-gray-700">First Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Last Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Phone Number</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Email Address</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Age</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Quests Completed This Month</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Total Hours Served</th>
          </tr>
        </thead>

        <!-- Table Body -->
        <tbody>
          <tr v-for="volunteerRecord in databaseRecap?.generateSingleMonthReport?.chariteerTimeResults" :key="volunteerRecord.chariteer.id" class="odd:bg-gray-50 even:bg-white">
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.nameFirst }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.nameLast }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.phoneNumber }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.emailAddress }}</td>
            <td class="p-2 text-sm text-gray-600">{{ calculateAge(volunteerRecord.chariteer.dateOfBirth ) }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.completedQuests }}</td>
            <td class="p-2 text-sm text-gray-600">{{ prettyVolunteerHours(volunteerRecord.combinedMinutes) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 2. First Time Volunteers -->
    <div class="bg-white text-gray-900 text-left shadow-lg rounded-lg p-5 mb-5">
      <h2 class="m-0 mb-2">First Time Volunteers in the {{ selectedTimeScale }} of {{ selectedOption }} ({{ databaseRecap?.generateSingleMonthReport?.chariteerFirstTimeResults?.length }})</h2>
      <table class="w-full text-left border-collapse">
        <!-- Table Header -->
        <thead>
          <tr class="bg-white">
            <th class="p-2 text-sm font-semibold text-gray-700">First Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Last Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Phone Number</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Email Address</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Age</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Quests Completed This Month</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Total Hours Served</th>
          </tr>
        </thead>

        <!-- Table Body -->
        <tbody>
          <tr v-for="volunteerRecord in databaseRecap?.generateSingleMonthReport?.chariteerFirstTimeResults" :key="volunteerRecord.chariteer.id" class="odd:bg-orange-200 even:bg-orange-100">
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.nameFirst }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.nameLast }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.phoneNumber }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.emailAddress }}</td>
            <td class="p-2 text-sm text-gray-600">{{ calculateAge(volunteerRecord.chariteer.dateOfBirth ) }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.completedQuests }}</td>
            <td class="p-2 text-sm text-gray-600">{{ prettyVolunteerHours(volunteerRecord.combinedMinutes) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 3. Retained Volunteers -->
    <div v-if="this.selectedTimeScale == 'Month'" class="bg-white text-gray-900 text-left shadow-lg rounded-lg p-5 mb-5">
      <h2 class="m-0 mb-2">Retained Volunteers in the {{ selectedTimeScale }} of {{ selectedOption }} ({{ databaseRecap?.generateSingleMonthReport?.retainedChariteerTimeResults?.length }})</h2>
      <h3 class="m-0 mb-2 text-gray-500">Displaying volunteer history from {{ previousPeriodName }} to {{ selectedOption }}</h3>
      <table class="w-full text-left border-collapse">
        <!-- Table Header -->
        <thead>
          <tr class="bg-white">
            <th class="p-2 text-sm font-semibold text-gray-700">First Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Last Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Phone Number</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Email Address</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Age</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Quests Completed This Month</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Total Hours Served</th>
          </tr>
        </thead>

        <!-- Table Body -->
        <tbody>
          <tr v-for="volunteerRecord in databaseRecap?.generateSingleMonthReport?.retainedChariteerTimeResults" :key="volunteerRecord.chariteer.id" class="odd:bg-blue-200 even:bg-blue-100">
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.nameFirst }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.nameLast }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.phoneNumber }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.chariteer.emailAddress }}</td>
            <td class="p-2 text-sm text-gray-600">{{ calculateAge(volunteerRecord.chariteer.dateOfBirth ) }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteerRecord.completedQuests }}</td>
            <td class="p-2 text-sm text-gray-600">{{ prettyVolunteerHours(volunteerRecord.combinedMinutes) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <!-- 4. Recorded Absences -->
    <div class="bg-white text-gray-900 text-left shadow-lg rounded-lg p-5">
      <h2 class="m-0 mb-2">Volunteers Recorded Absent in the {{ selectedTimeScale }} of {{ selectedOption }} ({{ databaseRecap?.generateSingleMonthReport?.absentChariteers?.length }})</h2>
      <table class="w-full text-left border-collapse">
        <!-- Table Header -->
        <thead>
          <tr class="bg-white">
            <th class="p-2 text-sm font-semibold text-gray-700">First Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Last Name</th>
            <th class="p-2 text-sm font-semibold text-gray-700">Times Recorded Absent During This Period</th>
          </tr>
        </thead>

        <!-- Table Body -->
        <tbody>
          <tr v-for="volunteer in databaseRecap?.generateSingleMonthReport?.absentChariteers" :key="volunteer.id" class="odd:bg-red-100 even:bg-red-200">
            <td class="p-2 text-sm text-gray-600">{{ volunteer.chariteerFirstName }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteer.chariteerLastName }}</td>
            <td class="p-2 text-sm text-gray-600">{{ volunteer.absentCount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import gql from "graphql-tag";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {
  isChariteerAllowedToPerformAction
} from "@/helpers/index.js";
import { defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "VolunteerRecap",
  components: {
  },
  data() {
    return {
      // Static options for the first dropdown
      // Removed "Day" and "Week" and "Custom Range" due to time constraints
      timeScales: ["Month", "Year"],

      // Selected values
      fuckle: "Month", // Default to "Month"
      buckle: null, // Holds the second dropdown's value
      customRange: null, // Custom range value for Date Picker

      // Dynamic options
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December",
      ],
      years: this.generateYears(2023), // Start from database redesign
      days: Array.from({ length: 31 }, (_, i) => i + 1),
      weeks: Array.from({ length: 52 }, (_, i) => `Week ${i + 1}`),
      ancientChariteers: [
        "next",
      ],
    };
  },
  watch: {
    selectedTimeScale(newScale) {
      // Automatically set the default option when time scale changes
      this.setDefaultOption(newScale);
    },
  },
  created() {
    // Set initial default option
    this.setDefaultOption(this.selectedTimeScale);
  },
  methods: {
    generateYears( startYear ) {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
    showYear ( timestamp ){
      const currentDate = new Date(timestamp);
      currentDate.setHours(currentDate.getHours() + 12); // Add 12 hours
      console.log("Date is " + currentDate);
      return currentDate.getFullYear();
    },
    setDefaultOption(scale) {
      const currentDate = new Date();
      switch (scale) {
        case "Month":
          this.selectedOption = this.months[currentDate.getMonth()]; // Current month
          break;
        case "Year":
          this.selectedOption = currentDate.getFullYear(); // Current year
          break;
        default:
          this.selectedOption = null;
      }
    },
    generateDateRange() {
      const scale = this.selectedTimeScale;
      const option = this.selectedOption;
      let dateRangeBeginning, dateRangeEnding;

      console.log("Does this get used anymore?");

      if (scale === "Month") {
        // Example: "October" → 2024-10-01 to 2024-11-01
        const monthIndex = this.months.indexOf(option); // Get 0-based month index
        const year = new Date().getFullYear(); // Default to current year
        dateRangeBeginning = new Date(Date.UTC(year, monthIndex, 1)).toISOString();
        dateRangeEnding = new Date(Date.UTC(year, monthIndex + 1, 1)).toISOString();
      } 
      else if (scale === "Year") {
        // Example: 2024 → 2024-01-01 to 2025-01-01
        const year = option; // Year is selected directly
        dateRangeBeginning = new Date(Date.UTC(year, 0, 1)).toISOString();
        dateRangeEnding = new Date(Date.UTC(year + 1, 0, 1)).toISOString();
      } 
      else if (scale === "Day") {
        // Example: "2024-12-14" → One full day
        const year = new Date().getFullYear();
        const monthIndex = new Date().getMonth(); // Default to current month
        const day = option; // Day number
        dateRangeBeginning = new Date(Date.UTC(year, monthIndex, day)).toISOString();
        dateRangeEnding = new Date(Date.UTC(year, monthIndex, day + 1)).toISOString();
      } 
      else if (scale === "Week") {
        // Example: "Week 1" → Assume starting on Monday
        const year = new Date().getFullYear();
        const weekNumber = parseInt(option.split(" ")[1], 10); // Extract week number
        const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
        const daysToAdd = (weekNumber - 1) * 7 - firstDayOfYear.getUTCDay() + 1;
        const weekStart = new Date(Date.UTC(year, 0, 1 + daysToAdd));
        const weekEnd = new Date(Date.UTC(year, 0, 8 + daysToAdd));
        dateRangeBeginning = weekStart.toISOString();
        dateRangeEnding = weekEnd.toISOString();
      } 
      else if (scale === "Custom Range" && this.customRange) {
        // Use the selected custom range directly
        dateRangeBeginning = this.customRange.start.toISOString();
        dateRangeEnding = this.customRange.end.toISOString();
      }

      console.log("dateRangeBeginning:", dateRangeBeginning);
      console.log("dateRangeEnding:", dateRangeEnding);

      return { dateRangeBeginning, dateRangeEnding };
    },
    prettyVolunteerHours( minutes )
    {
      var hours = (minutes / 60);
      var spareMinutes = (minutes % 60);
      var prettyText = hours.toLocaleString('en-US') + " h";

      if ( spareMinutes > 0 )
      {
        prettyText = prettyText + ", " + spareMinutes + " min";
      }

      return prettyText;
    },
    calculatedVolunteerHours( minutes )
    {
      var hours = (minutes / 60);
      // var spareMinutes = (minutes % 60);

      return hours;
    },
    calculateAge( dob )
    {
      // This should be a utility. You copied this from OSVQuestDetails, Frank!
      // Only reason it might not be a utility - if we're looking at data from 2021,
      // we should see how old the person was BACK THEN and not their current age...
      var age = -1;
      var today = new Date();
      var birthDate = new Date(dob);
      age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },
    handleCheckListButton()
    {
      console.log("Checking the tiny list of test volunteers!");
      console.log(this.ancientChariteers);

      let i = 0;
      for ( i; i < this.ancientChariteers.length; i++ )
      {
        this.checkIfChariteerIsInDatabase(this.ancientChariteers[i]);        
      }
    },
    async checkIfChariteerIsInDatabase(lookingForThisGui) {
      // console.log("Is this user in our new Chariteer database? " + lookingForThisGui);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
          query GetChariteerById {
            chariteer (id: "${lookingForThisGui}") {
              id
              username
              nameFirst
              nameMiddle
              nameLast
              playerAccountType
              subscriptionStatus
              contracts {
                id
                expectedArrivalAt
                expectedDepartureAt
                actualArrivalAt
                actualDepartureAt
                status
              }
              emailAddress
              dateOfBirth
              favoriteDistricts {
                id
              }
            }
          } 
          `
        })
      });

      // console.log("Chariteer Search Result:");
      // console.log(result);

      // Data will be the actual Chariteer block returned by the Query.
      // Maybe this is how we can set our State?
      const data = await result.json();
      // console.log(data);

      if (data.errors && data.errors.length > 0) {
        // console.log(data.errors[0].extensions.code);

        if (data.errors[0].extensions.code == 404) {
          // Add the Chariteer
          console.log("Could not find " + lookingForThisGui + ", adding...");
          this.addAncientChariteerToDB(lookingForThisGui);
        }
      } else {
        // Do nothing
        console.log(lookingForThisGui + " is already in the database!");
      }

      return data;
    },
    async addAncientChariteerToDB ( playerId )
    {
      // Get an old volunteer who never logged into the new database,
      // fetch their old PlayFab data, and make a row in our new system.

      var newChariteerRowData = {};

      // STEP 1. FETCH PERSONAL INFO
      // Get Personal Info for this Chariteer with PLAYER PUBLISHER READ ONLY DATA
      var getPlayerPublisherDataRequest = {
        PlayFabId: playerId
      };
      await window.PlayFabClientSDK.GetUserPublisherReadOnlyData(
        getPlayerPublisherDataRequest
      )
      .then(response => {
        // console.log("Downloaded PP (RO) Data for " + response.data.Data.NameFirst.Value + " " + response.data.Data.NameLast.Value)
        // console.log(response)
        // console.log(response.Request.PlayFabId)

        // console.log("Step 1 complete, we're working on a JSON object here.");
        newChariteerRowData.nameFirst = response.data.Data.NameFirst.Value;
        newChariteerRowData.nameLast = response.data.Data.NameLast.Value;
        newChariteerRowData.phoneNumber = response.data.Data.PhoneNumber.Value;
        newChariteerRowData.dateOfBirth = response.data.Data.DateOfBirth.Value;
      })
      .catch(error => {
        console.log('There was an error getting Player Publisher Read Only Data', error.response)
        console.log(error)
      });

      // STEP 1b. EMAIL ADDRESS
      // Get the Email for this Chariteer with GET PLAYER PROFILE
      var getPlayerProfileRequest = {
        PlayFabId: playerId,
        ProfileConstraints: {
          ShowDisplayName: true,
          ShowContactEmailAddresses: true
        }
      };
      await window.PlayFabClientSDK.GetPlayerProfile(getPlayerProfileRequest)
        .then(response => {
          // console.log("Downloaded Profile Data for " + response.data.PlayerProfile.DisplayName)
          // console.log(response)

          newChariteerRowData.id = playerId;
          newChariteerRowData.username = response.data.PlayerProfile.DisplayName;

          if (
            response.data.PlayerProfile.ContactEmailAddresses != null &&
            response.data.PlayerProfile.ContactEmailAddresses.length > 0
          ) {
            // Just use the first email
            newChariteerRowData.realEmailAddress =
              response.data.PlayerProfile.ContactEmailAddresses[0].EmailAddress;
          } else {
            // Set it to a bad display value
            newChariteerRowData.realEmailAddress =
              "Playfab Error Fetching Email";
          }

          //console.log("Step 2 complete, we're working on a JSON object here.");
        })
        .catch(error => {
          console.log("There was an error getting the Player Profile for someone.")
          console.log(error)
        });

        // STEP 1c. TITLE DATA
        // Get Title Data for this Chariteer with PLAYER READ ONLY TITLE DATA
        // - - - - - Skipping quests for now - - - - - -

      // STEP 2. ADD NEW ROW
      // Now that we have the data, create a new row in the DB.
      // console.log("Creating a new row for " + playerId + " with this data:");
      // console.log(newChariteerRowData);
      const newChariteerResult = await this.createChariteerAccountOnDatabase(newChariteerRowData);
    },
    async createChariteerAccountOnDatabase( volunteerData ) {
        // Execution
        const result = await this.mutationCreateChariteerAccount( volunteerData );
        // console.log(volunteerData.nameFirst);
        // console.log(volunteerData.nameLast);

        if ( result.errors?.length > 0 ) {
          console.log("No, it didn't work!");
          console.log(result.errors);
        }
        else
        {
          console.log(volunteerData.id + " (" + volunteerData.nameFirst + ") added!");
          console.log(result);
        }
    },
    async mutationCreateChariteerAccount ( volunteerData )
    {
      // console.log("Time to add " + volunteerData.nameFirst + "!");

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation CreateChariteer ($CCI: CreateChariteerInput!) {
                createChariteer(createChariteerInput: $CCI) {
                  id,
                  username,
                  emailAddress,
                  phoneNumber,
                  dateOfBirth,
                  playerAccountType,
                  nameFirst,
                  nameLast
                }
              }
            `,
          variables: {
              "CCI": {
                "id": volunteerData.id,
                "username": volunteerData.username,
                "emailAddress": ("" + volunteerData.emailAddress),
                "phoneNumber": volunteerData.phoneNumber,
                "dateOfBirth": volunteerData.dateOfBirth,
                "playerAccountType": "Chariteer",
                "nameFirst": volunteerData.nameFirst,
                "nameLast": volunteerData.nameLast
              }
            }
          })
        })
  
        const data = await result.json();
        return data;
    },
    downloadChariteerDataAsCSVFile()
    {
      console.log("Downloading CSV data!");
      // Do it
      // https://stackoverflow.com/questions/58292771/downloading-a-csv-of-file-using-vue-and-js

      // It needs to be in an array
      const array = Object.values(this.databaseRecap?.generateSingleMonthReport?.chariteerTimeResults);
      
      let csv = 'First Name,Last Name,Phone Number,Email Address,Date of Birth,Completed Quests,Service Hours\n';
      array.forEach((row) => {
              csv += row.chariteer.nameFirst + ',';
              csv += row.chariteer.nameLast + ',';
              csv += row.chariteer.phoneNumber + ',';
              csv += row.chariteer.emailAddress + ',';
              csv += row.chariteer.dateOfBirth.substring(0, 10) + ',';
              csv += row.completedQuests + ',';
              csv += this.calculatedVolunteerHours(row.combinedMinutes) + ',';
              csv += "\n";
      });

      //csv += row.join(',');
  
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      let targetYear; 
      if ( this.selectedTimeScale == "Month" )
      {
        targetYear = this.dateRange.dateRangeBeginning.substring(0, 4);
        anchor.download = this.$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderName + '-' + this.selectedOption + "-" + targetYear + '.csv';
      }
      else
      {
        targetYear = this.selectedOption;
        anchor.download = this.$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderName + '-' + "YearInReview" + "-" + targetYear + '.csv';
      }
      anchor.click();
      
    },
  },
  setup() {
    // Setting up Toast!
    const notifyError = text => {
      toast.error(text, {
        autoClose: false
      }); // ToastOptions
    };

    const notifySuccess = text => {
      toast.success(text, {
        autoClose: 10000
      }); // ToastOptions
    };

    // Access Vuex store
    const store = useStore();

    // We may as well tailor this page toward the current month the user is in
    // THIS WAS MY TESTING VERSION
    // let initialDate = new Date();
    // console.log("The initial date would be " + initialDate + ".");
    // let year = initialDate.getFullYear();
    // let month = initialDate.getMonth();

    // let startOfMonth = new Date(year, month, 1); // Start of the current month
    // let endOfMonth = new Date(year, month + 1, 0); // End of the current month
    
    // console.log(
    //   "Fetching the volunteer recap from " + startOfMonth + " to " + endOfMonth + "..."
    // );

    // let recapStartDateTime = ref(startOfMonth);
    // let recapEndDateTime = ref(endOfMonth);
    const route = useRoute();

    // Reactive state for dropdowns
    const selectedTimeScale = ref("Month");
    const selectedOption = ref(new Date().toLocaleString("default", { month: "long" })); // Default to current month

    // Reactive data range
    const dateRange = ref({
      dateRangeBeginning: null,
      dateRangeEnding: null,
    });

    // Our preferred year
    let preferredYear = new Date().getFullYear();

    // Function to generate date range based on selected inputs
    const generateDateRange = () => {
      const scale = selectedTimeScale.value;
      const option = selectedOption.value;
      let dateRangeBeginning, dateRangeEnding;

      if (scale === "Month") {
        const monthIndex = new Date(`${option} 1, ${preferredYear}`).getMonth();
        dateRangeBeginning = new Date(Date.UTC(preferredYear, monthIndex, 1)).toISOString();
        dateRangeEnding = new Date(Date.UTC(preferredYear, monthIndex + 1, 1)).toISOString();
      } else if (scale === "Year") {
        dateRangeBeginning = new Date(Date.UTC(option, 0, 1)).toISOString();
        dateRangeEnding = new Date(Date.UTC(option + 1, 0, 1)).toISOString();
        preferredYear = new Date(Date.UTC(option, 0, 2)).getFullYear();
      }

      // Update the reactive range
      dateRange.value = { dateRangeBeginning, dateRangeEnding };
      console.log("Requerying data with:", dateRange.value);
      console.log("Start will be " + dateRange.value.dateRangeBeginning);
      console.log("End will be " + dateRange.value.dateRangeEnding);
      console.log("Current preferred year: " + preferredYear);
    };

    console.log(
      "Selected Managed Provider ID is now " + store.state.currentlySelectedManagedProviderGroup?.ManagedProviderId
    );

    let tempProviderID = store.state.currentlySelectedManagedProviderGroup?.ManagedProviderId;
    if ( tempProviderID == undefined )
      tempProviderID = "foo";

    console.log(tempProviderID);

    const { result, loading, error, refetch, onResult } = useQuery(
      gql`
        query Banana($GCI: GetChariteersInput!) {
          generateSingleMonthReport(getChariteersInput: $GCI) {
            chariteerTimeResults {
              chariteer {
                  nameFirst
                  nameLast
                  phoneNumber
                  emailAddress
                  dateOfBirth
              }
              combinedMinutes
              completedQuests
            }
            serviceMinutes
            chariteerFirstTimeResults {
              chariteer {
                  nameFirst
                  nameLast
                  phoneNumber
                  emailAddress
                  dateOfBirth
              }
              combinedMinutes
              completedQuests
            }
            absentChariteers {
              chariteerId
              chariteerFirstName
              chariteerLastName
              absentCount
            }
            retainedChariteerTimeResults {
              chariteer {
                  nameFirst
                  nameLast
                  phoneNumber
                  emailAddress
                  dateOfBirth
              }
              combinedMinutes
              completedQuests
            }
            retentionPercentage
          }
        }
      `,
      // This is variables - and it has to look like this
      {
        GCI: {
          specificProvider: tempProviderID,
          dateRangeBeginning: dateRange.value.dateRangeBeginning,
          dateRangeEnding: dateRange.value.dateRangeEnding
        }
      }
    );

    // Watch for changes in the date range and re-run the query
    watch([selectedTimeScale, selectedOption, store.state.currentlySelectedManagedProviderGroup], () => {
      console.log("Selected Provider is " + store.state.currentlySelectedManagedProviderGroup?.ManagedProviderId);
      generateDateRange();
      refetch({
        GCI: {
          specificProvider: store.state.currentlySelectedManagedProviderGroup?.ManagedProviderId,
          dateRangeBeginning: dateRange.value.dateRangeBeginning,
          dateRangeEnding: dateRange.value.dateRangeEnding,
        },
      }).then(() => {
        console.log("Query refetched with new date range: " + dateRange.value.dateRangeBeginning + " and " + dateRange.value.dateRangeEnding);
      });
    });

    // Call generateDateRange initially to populate default values
    generateDateRange();
    console.log("Does it matter that we're calling foo?");
    refetch({
        GCI: {
          specificProvider: "foo",
          dateRangeBeginning: dateRange.value.dateRangeBeginning,
          dateRangeEnding: dateRange.value.dateRangeEnding,
        },
      })

    console.log("Refetching data starting with " + dateRange.value.dateRangeBeginning);
    console.log(result);
    console.log(error);

    return {
      databaseRecap: result || null,
      loading,
      error,
      refetch,
      onResult,
      notifyError,
      notifySuccess,
      // ourManagedProviderID: props.selectedManagedProviderId,
      selectedTimeScale,
      selectedOption,
      dateRange,
      preferredYear
    };
  },
  computed: {
    prettyServiceMinutes() {
      if ( this.databaseRecap != null )
      {
        var hours = (this.databaseRecap.generateSingleMonthReport.serviceMinutes / 60);
        hours = Math.trunc(hours);
        var spareMinutes = (this.databaseRecap.generateSingleMonthReport.serviceMinutes % 60);
        var prettyText = hours.toLocaleString('en-US') + " hours";

        if ( spareMinutes > 0 )
        {
          prettyText = prettyText + ", " + spareMinutes + " min";
        }

        return prettyText;
      }
      else
        return "No hours recorded";
    },
    prettyRetentionRate() {
      if ( this.databaseRecap != null )
        return this.databaseRecap.generateSingleMonthReport.retentionPercentage.toFixed(2);
      else
        return "0";
    },
    prettyProviderName() {
      let providerName = this.$store.state.currentlySelectedManagedProviderGroup?.ManagedProviderName;

      return providerName;
    },
    calculatedDollarValue() {
      if ( this.databaseRecap != null )
      {
        // Each minute of volunteer labor is like 50 cents:
        var dollars = this.databaseRecap.generateSingleMonthReport.serviceMinutes * 0.55;
        return dollars.toLocaleString('en-US', { minimumFractionDigits: 2 });
      }
      else
        return 0;
    },
    dynamicOptions() {
      // Return dynamic options based on selected time scale
      switch (this.selectedTimeScale) {
        case "Day":
          return this.days;
        case "Week":
          return this.weeks;
        case "Month":
          return this.months;
        case "Year":
          return this.years;
        default:
          return [];
      }
    },
    previousPeriodName () {
      if ( this.selectedTimeScale == "Month" )
      {
        // This isn't as easy as I thought, because we need to get those variables
        // we're using in the refetch to determine what month is before our selected month!

        return "previous month";
      }

      return "foo";
    }
  }
}
</script>