export const siftVolunteerContracts = ( pledgedContractsArray ) => {
    // console.log("Sifting Contracts...");
    // console.log(pledgedContractsArray);
    
    // Null check
    if ( !pledgedContractsArray )
    {
        return [];
    }

    return pledgedContractsArray.filter(contract => contract.abandonedAt === null && contract.guildId === null);
}

export const siftVolunteerContractsSingleProvider = ( pledgedContractsArray ) => {
    console.log("Sifting Contracts...");
    console.log(pledgedContractsArray);    
    
    // Null check
    if ( !pledgedContractsArray )
    {
        return [];
    }

    console.log("Provider ID 1 is " + pledgedContractsArray.find(Boolean)?.quest?.providerId);
    const theProviderID = pledgedContractsArray.find(Boolean)?.quest?.providerId;

    if ( !theProviderID )
    {
        console.log("We have no Providers to manage");
        return [];
    }

    return pledgedContractsArray.filter(contract => contract.quest.providerId === theProviderID && contract.guildId === null);
}

export const siftGuildContracts = ( pledgedContractsArray ) => {
    // console.log("Sifting Guild Contracts...");
    // console.log(pledgedContractsArray);
    
    // Null check
    if ( !pledgedContractsArray )
    {
        return [];
    }

    return pledgedContractsArray.filter((contract) => contract.abandonedAt === null && contract.guildId != null);
}

export const isChariteerManager = ( MGMArray, providerId) => {
    return MGMArray.some(item => item.ManagedProviderId == providerId)
}

export const isChariteerAllowedToPerformAction = ( membershipData, guildRBAC, abilityName, actionChar, humanReadableActionForLogs ) => {
    
    // This is for single actions, where actionChar is a Char instead of an array.
    // console.log("Can this Chariteer " + humanReadableActionForLogs + "?");

    // We can't proceed if either of these are null:
    if ( membershipData != null && guildRBAC != null )
    {
        // For each Role, find an excuse to allow this user to do this.
        // Return TRUE the moment we know for sure that we can do this.
        // For example, a user with 6 Roles in a Group returns TRUE even if it's the first Role
        // that we check and we know that Role can do the thing.
        // console.log("Let's check \"" + abilityName + "\" for the char '" + actionChar + "'.");
        // console.log(membershipData);
        // console.log(guildRBAC);

        // Step 1. Is this an Ability we know about?
        const theAbility = guildRBAC?.groupRBAC?.find(item => item.ability === abilityName) || null;
        // console.log(theAbility);

        if ( theAbility == null )
        {
            console.log("We don't recognize that ability, so no Role could have permission to " + humanReadableActionForLogs + ".");
            return false;
        }

        // Enter a loop for each Role this user has
        let i = 0;

        for ( i; i < membershipData.Roles.length; i++ )
        {
            // Step 2a. Is this Role the protected "admins" Role that has super access?
            // console.log("Checking to see if " + membershipData.Roles[i].RoleId + " is the Super Role.");
            
            if ( membershipData.Roles[i].RoleId == "admins" )
            {
                console.log("Nothing stops admins!");
                return true;
            }

            // Step 2b. Is this a Role we know about, within this Ability?
            console.log("Checking to see if " + membershipData.Roles[i].RoleId + " are even mentioned in Ability \"" + abilityName + "\"...");
            const theRoleWithinTheAbility = theAbility.roleCRUDS?.find(item => item.role === membershipData.Roles[i].RoleId) || null;
            // console.log(theRoleWithinTheAbility);

            if ( theRoleWithinTheAbility == null )
            {
                // This Role has no permissions at all related to this Ability!
                console.log(membershipData.Roles[i].RoleId + " is not in Ability \"" + abilityName + "\"...");

                // That's it for this loop. Try again with another Role, if we have one.
            }
            else
            {
                // This Role might have permissions related to this Ability...
                // Step 3. Is this particular action approved?
                const theActionWithinTheRoleWithinTheAbility = theRoleWithinTheAbility.CRUD.includes(actionChar);
                // console.log(theActionWithinTheRoleWithinTheAbility);

                if ( theActionWithinTheRoleWithinTheAbility == false )
                {
                    console.log("We don't see " + actionChar + " as an action, so you don't have permission to " + humanReadableActionForLogs + ".");
                    
                    // Bust! Try again with the next Role.
                }
                else
                {
                    console.log(membershipData.Roles[i].RoleId + " can " + humanReadableActionForLogs + " (" + actionChar + ")!");

                    return true;
                }
            }
        }

        

        console.log("User has no permitted Roles, and fails. Denied!");        
        return false;
    }
    else
    {
        console.log("Unable to evaluate without Membership and Guild Rules. Access denied!");
        return false;
    }
}

export const isChariteerAllowedToPerformStaticAction = ( membershipData, abilityName, actionChar, humanReadableActionForLogs ) => {
    
    // This is for single actions, where actionChar is a Char instead of an array.
    console.log("Can this Chariteer " + humanReadableActionForLogs + "?");

    // Sloppily coppilied over from FDCalendar.
    // Refactor!
    var RBACstring = `
      {
        \"groupRBAC\":
        [
          {\"ability\":\"memberFullNames\",\"roleCRUDS\":[{\"role\":\"admins\",\"CRUD\":[\"R\"]},{\"role\":\"boardMembers\",\"CRUD\":[\"R\"]},{\"role\":\"employees\",\"CRUD\":[\"R\"]},{\"role\":\"managers\",\"CRUD\":[\"R\"]},{\"role\":\"staff\",\"CRUD\":[\"R\"]},{\"role\":\"nightCaseManagers\",\"CRUD\":[\"R\"]},{\"role\":\"kitchenManagers\",\"CRUD\":[\"R\"]},{\"role\":\"frontDeskStaff\",\"CRUD\":[\"R\"]}]},
          {\"ability\":\"memberContactInfo\",\"roleCRUDS\":[{\"role\":\"boardMembers\",\"CRUD\":[\"R\"]},{\"role\":\"employees\",\"CRUD\":[\"R\"]},{\"role\":\"managers\",\"CRUD\":[\"R\"]},{\"role\":\"staff\",\"CRUD\":[\"R\"]},{\"role\":\"nightCaseManagers\",\"CRUD\":[\"R\"]},{\"role\":\"kitchenManagers\",\"CRUD\":[\"R\"]},{\"role\":\"frontDeskStaff\",\"CRUD\":[\"R\"]}]},
          {\"ability\":\"chariteerAttendance\",\"roleCRUDS\":[{\"role\":\"employees\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"managers\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"staff\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"nightCaseManagers\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"kitchenManagers\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"frontDeskStaff\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]}]},
          {\"ability\":\"bypassAgePolicy\",\"roleCRUDS\":[{\"role\":\"trustedMinors\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]}]},
          {\"ability\":\"quest\",\"roleCRUDS\":[{\"role\":\"employees\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"managers\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"staff\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"coordinators\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]},{\"role\":\"captains\",\"CRUD\":[\"R\",\"U\"]},{\"role\":\"trustedMinors\",\"CRUD\":[\"R\"]}]},
          {\"ability\":\"chariteerAlert\",\"roleCRUDS\":[{\"role\":\"managers\",\"CRUD\":[\"C\",\"R\",\"U\",\"D\"]}]}
        ]
      }
      `;

    var guildRBAC = JSON.parse(RBACstring);

    // We can't proceed if this is null:
    if ( membershipData != null )
    {
        // Step 1. Is this an Ability we know about?
        const theAbility = guildRBAC?.groupRBAC?.find(item => item.ability === abilityName) || null;
        // console.log(theAbility);

        if ( theAbility == null )
        {
            console.log("We don't recognize that ability, so no Role could have permission to " + humanReadableActionForLogs + ".");
            return false;
        }

        // Enter a loop for each Role this user has
        let i = 0;

        for ( i; i < membershipData.Roles.length; i++ )
        {
            // Step 2a. Is this Role the protected "admins" Role that has super access?
            // console.log("Checking to see if " + membershipData.Roles[i].RoleId + " is the Super Role.");
            
            if ( membershipData.Roles[i].RoleId == "admins" )
            {
                console.log("Nothing stops admins!");
                return true;
            }

            // Step 2b. Is this a Role we know about, within this Ability?
            console.log("Checking to see if " + membershipData.Roles[i].RoleId + " are even mentioned in Ability \"" + abilityName + "\"...");
            const theRoleWithinTheAbility = theAbility.roleCRUDS?.find(item => item.role === membershipData.Roles[i].RoleId) || null;
            // console.log(theRoleWithinTheAbility);

            if ( theRoleWithinTheAbility == null )
            {
                // This Role has no permissions at all related to this Ability!
                console.log(membershipData.Roles[i].RoleId + " is not in Ability \"" + abilityName + "\"...");

                // That's it for this loop. Try again with another Role, if we have one.
            }
            else
            {
                // This Role might have permissions related to this Ability...
                // Step 3. Is this particular action approved?
                const theActionWithinTheRoleWithinTheAbility = theRoleWithinTheAbility.CRUD.includes(actionChar);
                // console.log(theActionWithinTheRoleWithinTheAbility);

                if ( theActionWithinTheRoleWithinTheAbility == false )
                {
                    console.log("We don't see " + actionChar + " as an action, so you don't have permission to " + humanReadableActionForLogs + ".");
                    
                    // Bust! Try again with the next Role.
                }
                else
                {
                    console.log(membershipData.Roles[i].RoleId + " can " + humanReadableActionForLogs + " (" + actionChar + ")!");

                    return true;
                }
            }
        }

        

        console.log("User has no permitted Roles, and fails. Denied!");        
        return false;
    }
    else
    {
        console.log("Unable to evaluate without Membership and Guild Rules. Access denied!");
        return false;
    }
}

export const properMedallionColor = ( serviceTag ) =>
{
    // Find the right color!

    switch ( serviceTag )
    {
        case "beast":
            return "quest-beast";
        case "blood":
            return "quest-blood";
        case "body":
            return "quest-body";
        case "craft":
            return "quest-craft";
        case "cure":
            return "quest-cure";
        case "defender":
            return "quest-defender";
        case "earth":
            return "quest-earth";
        case "fire":
            return "quest-fire";
        case "food":
            return "quest-food";
        case "garment":
            return "quest-garment";
        case "hygiene":
            return "quest-hygiene";
        case "life":
            return "quest-life";
        case "mind":
            return "quest-mind";
        case "nature":
            return "quest-nature";
        case "shadow":
            return "quest-shadow";
        case "soul":
            return "quest-soul";
        case "speech":
            return "quest-speech";
        case "storm":
            return "quest-storm";
        case "time":
            return "quest-time";
        case "toxin":
            return "quest-toxin";
        case "treasure":
            return "quest-treasure";
        case "voyage":
            return "quest-voyage";
        case "war":
            return "quest-war";
        case "water":
            return "quest-water";
        case "youth":
            return "quest-youth";
        default:
            return "quest-uncategorized";
    }
}

export const TAILWIND_HEX = {
    'quest-beast': '#643C1F',
    'quest-blood': '#9E011B',
    'quest-body': '#F59736',
    'quest-craft': '#D5A724',
    'quest-cure': '#F9C0DC',
    'quest-defender': '#3E6FB3',
    'quest-earth': '#8A6C58',
    'quest-fire': '#DB1E29',
    'quest-food': "#CCA58A",
    'quest-garment': "#EC5097",
    'quest-hygiene': "#44C0C1",
    'quest-life': "#F6AEAA",
    'quest-mind': "#A3A2D2",
    'quest-nature': "#72BC42",
    'quest-shadow': "#3D3D3D",
    'quest-soul': "#DDDDDD",
    'quest-speech': "#ABD6A8",
    'quest-storm': "#652577",
    'quest-time': "#AAAAAA",
    'quest-toxin': "#995CA2",
    'quest-treasure': "#F9E138",
    'quest-voyage': '#2EBFDE',
    'quest-war': '#438243',
    'quest-water': '#316188',
    'quest-youth': '#BCE5F6',
    'quest-uncategorized': '#CACACA'
}

export const formatAMPM = ( hours, minutes ) => {
    //var hours = date.getHours();
    //var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
}

export const formatPrettyHours = ( mins ) => {

    var hours = mins / 60;
    var minutes = mins % 60;
    hours = Math.trunc(hours);

    if ( hours < 1 )
        return minutes + " minutes";
    else if ( hours == 1 && minutes == 0 )
        return hours + " hour";
    else if ( hours == 1 && minutes > 0 )
        return hours + " hour, " + minutes + " minutes";
    else if ( hours > 1 && minutes == 0 )
        return hours + " hours";
    else
        return hours + " hours, " + minutes + " minutes";
}


// TO DO:

// Shelter will need to take attendance for Groups now.
// Volunteer ring is NOT appearing for Guilds! Add it back.
// After I post a quest on the Calendar, the pop up needs to close

// The Guild Code input box should force characters to be capitalized

// i love the way stripe does tooltips (the add new product page)

// = = = WILL QUESTIONS = = =
/* Start / end dates calendar API:
    START: DateTime.now().minus({days: 5}).toJSDate()
    END: DateTime.now().plus({days: 5}).toJSDate()
    should do the trick, then graphql endpoint handles get from between date range
    // I don't think DateTime.now works if we're looking at a different month than the one we're in, right? Oct, Dec
*/

// The past month needs to be grey if it's complete...
// Find a Vue-3 type autocomplete searchy thing
// Code in a way for Group reservations to be CANCELLED
// What about editing some aspects of a Quest in a little mobile phone window?
// We need a raid confirmation window to pop up and say "OK you're gonna reserve this. And by the way, these 8 people? Do we boot them?"


